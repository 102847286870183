import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { passwordCriteria } from 'utils/validation/passwordValidation';
import './style.scss';

const NewPasswordFormField = ({ input, meta: { error, touched, dirty }, ...rest }) => {
  const fieldErrorMessage = error && error.fieldErrorMessage;
  const showError = touched && !!fieldErrorMessage;
  const passwordCriteriaErrors = error && error.passwordCriteriaErrors;
  return (
    <div className="new-password-form-field">
      <TextField {...input} error={showError} helperText={showError ? fieldErrorMessage : ' '} {...rest} />
      <div className="new-password-criteria">
        {Object.entries(passwordCriteria).map(([key, criterion]) => {
          const criterionHasError = passwordCriteriaErrors && passwordCriteriaErrors[key];
          const showColor = dirty || touched;
          const isValid = input.value && !criterionHasError;
          const colorClass = showColor && (isValid ? 'valid' : 'invalid');
          return (
            <div key={key} className={classNames('new-password-criterion', colorClass)}>
              {(!showColor || isValid) && <DoneIcon className="new-password-form-done-icon" />}
              {showColor && !isValid && <CloseIcon className="new-password-form-close-icon" />}
              <div className="criterion-text">{criterion.message}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

NewPasswordFormField.propTypes = {
  // The input and meta props are provided by redux-form.
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default NewPasswordFormField;
