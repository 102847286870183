// Error logging
import * as Sentry from '@sentry/browser';
import { pick } from './immutableUtils';

const init = options => {
  const envSpecificOptions =
    process.env.NODE_ENV === 'production'
      ? {}
      : {
          beforeBreadcrumb(breadcrumb) {
            return breadcrumb.category === 'console' ? null : breadcrumb; // Ignore console logs in development to prevent the payload from getting too large
          },
        };

  Sentry.init({
    dsn: 'https://616114c5d7dd41d1b3454833df433f3a@sentry.io/1386002',
    environment: process.env.NODE_ENV,
    release: `wnsw-phn-ui@${process.env.REACT_APP_VERSION}`,
    ...envSpecificOptions,
    ...options,
  });
};

export const logError = (error, errorInfo) => {
  Sentry.withScope(scope => {
    Object.keys(errorInfo).forEach(key => {
      scope.setExtra(key, errorInfo[key]);
    });
    Sentry.captureException(error);
  });
};

const getErrorId = () => Sentry.lastEventId();

export const logFeedback = () => {
  Sentry.showReportDialog({ eventId: getErrorId() });
};

export const errorMiddleware = store => {
  Sentry.addGlobalEventProcessor(event => {
    const state = store.getState();
    const user = state.getIn(['global', 'data', 'user'])
      ? pick(state.getIn(['global', 'data', 'user']), ['email', 'username']).toJS()
      : {};
    const logState = {
      content: pick(state.getIn(['content', 'data']), [
        'data_up_to',
        'facility',
        'measure_averages',
        'average_by_month',
        'facility_by_month',
        'facility_measures',
      ]).toJS(),
      admin: {
        filters: state.getIn(['admin', 'filters']).toJS(),
        group: state.getIn(['groupDetails', 'data', 'group']).toJS(),
        user: state.getIn(['userDetails', 'data', 'user']).toJS(),
      },
    };

    return {
      ...event,
      extra: {
        ...event.extra,
        'redux:state': logState,
      },
      user: {
        ...event.user,
        ...user,
      },
    };
  });

  return next => action => {
    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
    });

    return next(action);
  };
};

export default init;
