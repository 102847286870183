import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Link, withRouter } from 'react-router-dom';
import './style.scss';

const checkSelected = ({to, currentPath})=>{
  if(currentPath.includes('content')){
    const path = currentPath.split('/measure')
    return path[0] === to
  }
  return currentPath.startsWith(to)
}

const NavBarLink = ({ to, label, icon, currentPath, expanded, collapse }) => {
  const selected = checkSelected({to, currentPath})
  return (
    <Link to={to} className={selected ? 'selected' : undefined} onClick={collapse}>
      {!expanded && (
        <Tooltip title={label} placement="right" classes={{ popper: 'nav-bar-link-tooltip' }}>
          <div className="nav-bar-link-content">
            <div className="nav-bar-link-icon">{icon}</div>
            <div className="nav-bar-link-text">{label}</div>
          </div>
        </Tooltip>
      )}
      {expanded && (
        <div className="nav-bar-link-content">
          <div className="nav-bar-link-icon">{icon}</div>
          <div className="nav-bar-link-text">{label}</div>
        </div>
      )}
    </Link>
  );
};

const NavBar = ({ nav, expanded, collapse, toggleExpanded, location: { pathname } }) => (
  <div className="nav-bar no-print">
    <div className="nav-bar-logo">
      <div className="synergia-swirl" />
      <div className="synergia-text expanded-version">synergia</div>
      <div className="synergia-text collapsed-version">synergia</div>
      <div className="analytics-text">analytics</div>
    </div>
    <div className="nav-bar-links">
      {nav.map(navProps => (
        <NavBarLink key={navProps.label} {...navProps} currentPath={pathname} expanded={expanded} collapse={collapse} />
      ))}
    </div>
    <button className="expanded-toggle" onClick={toggleExpanded}>
      <ChevronRight />
      <div className="expanded-toggle-text">{expanded ? 'Click to collapse' : 'Click to expand'}</div>
    </button>
  </div>
);

NavBarLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  currentPath: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  collapse: PropTypes.func.isRequired,
};

NavBar.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.object).isRequired,
  expanded: PropTypes.bool.isRequired,
  collapse: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired, // Provided by react-router
};

export default withRouter(NavBar);
