/*
 * Facilities Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_FACILITIES = 'WNSW/FACILITIES/LOAD_FACILITIES';
export const LOAD_FACILITIES_SUCCESS = 'WNSW/FACILITIES/LOAD_FACILITIES_SUCCESS';
export const LOAD_FACILITIES_ERROR = 'WNSW/FACILITIES/LOAD_FACILITIES_ERROR';

export const TOGGLE_FACILITY_SELECTED = 'WNSW/FACILITIES/TOGGLE_FACILITY_SELECTED';
export const TOGGLE_ALL_FACILITIES = 'WNSW/FACILITIES/TOGGLE_ALL_FACILITIES';
export const RESET_SELECTED_FACILITIES = 'WNSW/FACILITIES/RESET_SELECTED_FACILITIES';
