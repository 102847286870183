/*
 * Groups Details Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_GROUP_DETAILS = 'WNSW/GROUP_DETAILS/LOAD_GROUP_DETAILS';
export const LOAD_GROUP_DETAILS_SUCCESS = 'WNSW/GROUP_DETAILS/LOAD_GROUP_DETAILS_SUCCESS';
export const LOAD_GROUP_DETAILS_ERROR = 'WNSW/GROUP_DETAILS/LOAD_GROUP_DETAILS_ERROR';

export const UPDATE_GROUP = 'WNSW/GROUP_DETAILS/UPDATE_GROUP';

export const SAVE_GROUP = 'WNSW/GROUP_DETAILS/SAVE_GROUP';
export const SAVE_GROUP_ERROR = 'WNSW/GROUP_DETAILS/SAVE_GROUP_ERROR';
export const SAVE_GROUP_SUCCESS = 'WNSW/GROUP_DETAILS/SAVE_GROUP_SUCCESS';

export const CREATE_GROUP = 'WNSW/GROUP_DETAILS/CREATE_GROUP';
export const CREATE_GROUP_ERROR = 'WNSW/GROUP_DETAILS/CREATE_GROUP_ERROR';
export const CREATE_GROUP_SUCCESS = 'WNSW/GROUP_DETAILS/CREATE_GROUP_SUCCESS';

export const RESET_GROUP = 'WNSW/GROUP_DETAILS/RESET_GROUP';

export const REMOVE_GROUP_MEMBER = 'WNSW/GROUP_DETAILS/REMOVE_GROUP_MEMBER';
export const GROUP_MEMBER_REMOVED = 'WNSW/GROUP_DETAILS/GROUP_MEMBER_REMOVED';

export const CHANGE_GROUP_SUSPEND_STATUS = 'WNSW/GROUP_DETAILS/CHANGE_GROUP_SUSPEND_STATUS';
export const CHANGE_GROUP_SUSPEND_STATUS_SUCCESS = 'WNSW/GROUP_DETAILS/CHANGE_GROUP_SUSPEND_STATUS_SUCCESS';
export const CHANGE_GROUP_SUSPEND_STATUS_ERROR = 'WNSW/GROUP_DETAILS/CHANGE_GROUP_SUSPEND_STATUS_ERROR';
