/*
 * Group Details reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import {
  LOAD_GROUP_DETAILS,
  LOAD_GROUP_DETAILS_ERROR,
  LOAD_GROUP_DETAILS_SUCCESS,
  UPDATE_GROUP,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  SAVE_GROUP_SUCCESS,
  SAVE_GROUP_ERROR,
  SAVE_GROUP,
  RESET_GROUP,
  GROUP_MEMBER_REMOVED,
  CHANGE_GROUP_SUSPEND_STATUS_SUCCESS,
  CHANGE_GROUP_SUSPEND_STATUS_ERROR
} from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    group: {
      name: '',
      group_type: '',
      description: '',
    },
    members: [],
    suspended:false
  },
  groupTypes: ['general', 'admin', 'cluster', 'specific'],
});

function removeGroupMember(state, memberId, memberType) {
  const key = memberType === 'user' ? 'username' : 'id';
  const index = state.getIn(['data', 'members']).findIndex(m => m.getIn([memberType, key]) === memberId);
  return index >= 0 ? state.updateIn(['data', 'members'], members => members.delete(index)) : state;
}

function groupDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_GROUP_DETAILS:
      return state.set('loading', true).set('error', false);
    case UPDATE_GROUP:
    case CHANGE_GROUP_SUSPEND_STATUS_SUCCESS:
      return state.setIn(['data', 'group', action.field], action.value);
    case LOAD_GROUP_DETAILS_SUCCESS:
      return state.set('data', fromJS(action.groupDetails)).set('loading', false);
    case CREATE_GROUP:
    case SAVE_GROUP:
      return state.set('loading', true);
    case CREATE_GROUP_ERROR:
    case SAVE_GROUP_ERROR:
    case LOAD_GROUP_DETAILS_ERROR:
    case CHANGE_GROUP_SUSPEND_STATUS_ERROR:
      return state.set('error', action.error).set('loading', false);
    case CREATE_GROUP_SUCCESS:
    case SAVE_GROUP_SUCCESS:
      return state.set('loading', false);
    case RESET_GROUP:
      return state.set('data', initialState.get('data'));
    case GROUP_MEMBER_REMOVED:
      return removeGroupMember(state, action.memberId, action.memberType);
    default:
      return state;
  }
}

export default groupDetailsReducer;
