export const lookupDomainIcon = domain => {
  switch (domain) {
    case 'ODGP':
      return 'odgp';
    case 'PIP QI':
      return 'pip_qi';
    case 'Chronic Disease Management':
      return 'patient';
    case 'Cardiovascular Disease':
      return 'heart-pulse';
    case 'Diabetes':
      return 'test-blood';
    case 'Population Health & Screening':
      return 'med-report';
    case 'Mental Health':
      return 'thought-bubble';
    case 'Respiratory':
      return 'lungs';
    case 'Aged Care':
      return 'medical-bag';
    case 'Aboriginal Medical Services':
      return 'medicine';
    case 'admin':
      return 'settings';
    default:
      return null;
  }
};

export const lookupViewTypeIcon = viewType => {
  switch (viewType) {
    case 'admin':
      return 'settings';
    case 'cluster':
      return 'google-circles-extended';
    case 'specific':
      return 'stethoscope';
    case 'users':
      return 'account';
    case 'facilities':
      return 'stethoscope';
    case 'views':
      return 'book-open-page-variant';
    case 'groups':
      return 'tag-multiple';
    default:
      return null;
  }
};
