import * as constants from './constants';

export const changePassword = (email, newPassword, resetToken) => ({
  type: constants.CHANGE_PASSWORD,
  email,
  newPassword,
  resetToken,
});

export const changePasswordError = error => ({
  type: constants.CHANGE_PASSWORD_ERROR,
  error,
});

export const changePasswordSuccess = () => ({
  type: constants.CHANGE_PASSWORD_SUCCESS,
});
