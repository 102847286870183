import { fromJS } from 'immutable';
import * as constants from './constants';

const initialState = fromJS({
  loading: false,
  sendEmailError: null,
  sendEmailSuccess: false,
  email: '',
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.INITIALIZE:
      return initialState;
    case constants.SEND_PASSWORD_RESET:
      return state
        .set('loading', true)
        .set('sendEmailError', null)
        .set('sendEmailSuccess', false);
    case constants.SEND_PASSWORD_RESET_ERROR:
      return state.set('sendEmailError', action.error).set('loading', false);
    case constants.SEND_PASSWORD_RESET_SUCCESS:
      return state
        .set('loading', false)
        .set('sendEmailSuccess', true)
        .set('email', action.email);
    default:
      return state;
  }
};

export default reducer;
