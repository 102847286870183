import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { API_URL } from '../../App/constants';
import { SEND_PASSWORD_RESET } from './constants';
import { sendPasswordResetError, sendPasswordResetSuccess } from './actions';

function* sendPasswordReset(action) {
  const requestURL = `${API_URL}/send-password-reset`;
  try {
    yield call(request, requestURL, { method: 'PUT', body: JSON.stringify({ email: action.email }) });
    yield put(sendPasswordResetSuccess(action.email));
  } catch (error) {
    yield put(sendPasswordResetError('Unable to reset password at this time.'));
  }
}

export default function* saga() {
  yield takeLatest(SEND_PASSWORD_RESET, sendPasswordReset);
}
