import * as constants from './constants';

export const initialize = () => ({
  type: constants.INITIALIZE,
});

export const sendPasswordReset = email => ({
  type: constants.SEND_PASSWORD_RESET,
  email,
});

export const sendPasswordResetError = error => ({
  type: constants.SEND_PASSWORD_RESET_ERROR,
  error,
});

export const sendPasswordResetSuccess = email => ({
  type: constants.SEND_PASSWORD_RESET_SUCCESS,
  email,
});
