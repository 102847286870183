import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import './style.scss';

const LoginAlert = ({ showing, text, onClose }) => (
  <div className={classNames('login-alert', showing && 'showing')} aria-hidden={!showing}>
    <div className="alert-box">
      <div className="login-alert-text">{text}</div>
      <CloseIcon className="close-icon" onClick={() => onClose()} />
    </div>
  </div>
);

LoginAlert.propTypes = {
  showing: PropTypes.bool.isRequired,
  text: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default LoginAlert;
