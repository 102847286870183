/*
 * User Details reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import {
  LOAD_USER_DETAILS,
  LOAD_USER_DETAILS_ERROR,
  LOAD_USER_DETAILS_SUCCESS,
  UPDATE_USER,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  SAVE_USER_SUCCESS,
  SAVE_USER_ERROR,
  SAVE_USER,
  RESET_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  USER_REMOVED_FROM_GROUP,
  CHANGE_USER_SUSPEND_STATUS,
  CHANGE_USER_SUSPEND_STATUS_SUCCESS,
  CHANGE_USER_SUSPEND_STATUS_ERROR,
} from './constants';

const initialState = fromJS({
  loading: false,
  loadingActionType: '',
  error: false,
  data: {
    user: {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      suspended:false
    },
    groups: [],
  },
  userRoles: ['admin'],
});

function removeUserFromGroup(state, groupId) {
  const index = state.getIn(['data', 'groups']).findIndex(group => group.get('id') === groupId);
  return index >= 0 ? state.updateIn(['data', 'groups'], groups => groups.delete(index)) : state;
}

function userDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_DETAILS:
    case RESET_PASSWORD:
      return state
        .set('loadingActionType', RESET_PASSWORD)
        .set('loading', true)
        .set('error', false);
    case UPDATE_USER:
      return state.setIn(['data', 'user', action.field], action.value);
    case LOAD_USER_DETAILS_SUCCESS:
      return state.set('data', fromJS(action.userDetails)).set('loading', false);
    case CREATE_USER:
    case SAVE_USER:
      return state.set('loading', true);
    case CREATE_USER_ERROR:
    case SAVE_USER_ERROR:
    case LOAD_USER_DETAILS_ERROR:
    case RESET_PASSWORD_ERROR:
    case CHANGE_USER_SUSPEND_STATUS_ERROR:
      return state.set('error', action.error).set('loading', false);
    case CREATE_USER_SUCCESS:
    case SAVE_USER_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return state.set('loading', false);
    case RESET_USER:
      return state.set('data', initialState.get('data'));
    case USER_REMOVED_FROM_GROUP:
      return removeUserFromGroup(state, action.groupId);
    case CHANGE_USER_SUSPEND_STATUS_SUCCESS:
      return state.setIn(['data','user','suspended'], action.suspended);
    case CHANGE_USER_SUSPEND_STATUS:
      return state
        .set('loadingActionType', CHANGE_USER_SUSPEND_STATUS)
        .set('loading', true)
        .set('error', false);
    default:
      return state;
  }
}

export default userDetailsReducer;
