/*
 * User manage - create/edit constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_USER_DETAILS = 'WNSW/USER_DETAILS/LOAD_USER_DETAILS';
export const LOAD_USER_DETAILS_SUCCESS = 'WNSW/USER_DETAILS/LOAD_USER_DETAILS_SUCCESS';
export const LOAD_USER_DETAILS_ERROR = 'WNSW/USER_DETAILS/LOAD_USER_DETAILS_ERROR';

export const UPDATE_USER = 'WNSW/USER_DETAILS/UPDATE_USER';

export const SAVE_USER = 'WNSW/USER_DETAILS/SAVE_USER';
export const SAVE_USER_ERROR = 'WNSW/USER_DETAILS/SAVE_USER_ERROR';
export const SAVE_USER_SUCCESS = 'WNSW/USER_DETAILS/SAVE_USER_SUCCESS';

export const SET_USER_GROUPS = 'WNSW/USER_DETAILS/SET_USER_GROUPS';
export const SET_USER_GROUPS_ERROR = 'WNSW/USER_DETAILS/SET_USER_GROUPS_ERROR';
export const SET_USER_GROUPS_SUCCESS = 'WNSW/USER_DETAILS/SET_USER_GROUPS_SUCCESS';

export const CREATE_USER = 'WNSW/USER_DETAILS/CREATE_USER';
export const CREATE_USER_ERROR = 'WNSW/USER_DETAILS/CREATE_USER_ERROR';
export const CREATE_USER_SUCCESS = 'WNSW/USER_DETAILS/CREATE_USER_SUCCESS';

export const RESET_PASSWORD = 'WNSW/USER_DETAILS/RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'WNSW/USER_DETAILS/RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'WNSW/USER_DETAILS/RESET_PASSWORD_SUCCESS';

export const RESET_USER = 'WNSW/USER_DETAILS/RESET_USER';

export const REMOVE_USER_FROM_GROUP = 'WNSW/VIEW_DETAILS/REMOVE_USER_FROM_GROUP';
export const USER_REMOVED_FROM_GROUP = 'WNSW/VIEW_DETAILS/USER_REMOVED_FROM_GROUP';

export const CHANGE_USER_SUSPEND_STATUS = 'WNSW/USER_DETAILS/CHANGE_USER_SUSPEND_STATUS';
export const CHANGE_USER_SUSPEND_STATUS_SUCCESS = 'WNSW/USER_DETAILS/CHANGE_USER_SUSPEND_STATUS_SUCCESS';
export const CHANGE_USER_SUSPEND_STATUS_ERROR = 'WNSW/USER_DETAILS/CHANGE_USER_SUSPEND_STATUS_ERROR';
