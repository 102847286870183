import React from 'react';
import './style.scss';

const Background = () => (
  <div className="background">
    <div className="background-waves" />
  </div>
);

export default Background;
