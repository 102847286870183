/*
 * Cohorts Details Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_COHORT_DETAILS = 'WNSW/COHORT_DETAILS/LOAD_COHORT_DETAILS';
export const LOAD_COHORT_DETAILS_SUCCESS = 'WNSW/COHORT_DETAILS/LOAD_COHORT_DETAILS_SUCCESS';
export const LOAD_COHORT_DETAILS_ERROR = 'WNSW/COHORT_DETAILS/LOAD_COHORT_DETAILS_ERROR';

export const UPDATE_COHORT = 'WNSW/COHORT_DETAILS/UPDATE_COHORT';

export const SAVE_COHORT = 'WNSW/COHORT_DETAILS/SAVE_COHORT';
export const SAVE_COHORT_ERROR = 'WNSW/COHORT_DETAILS/SAVE_COHORT_ERROR';
export const SAVE_COHORT_SUCCESS = 'WNSW/COHORT_DETAILS/SAVE_COHORT_SUCCESS';

export const CREATE_COHORT = 'WNSW/COHORT_DETAILS/CREATE_COHORT';
export const CREATE_COHORT_ERROR = 'WNSW/COHORT_DETAILS/CREATE_COHORT_ERROR';
export const CREATE_COHORT_SUCCESS = 'WNSW/COHORT_DETAILS/CREATE_COHORT_SUCCESS';

export const RESET_COHORT = 'WNSW/COHORT_DETAILS/RESET_COHORT';

export const REMOVE_COHORT_MEMBER = 'WNSW/COHORT_DETAILS/REMOVE_COHORT_MEMBER';
export const COHORT_MEMBER_REMOVED = 'WNSW/COHORT_DETAILS/COHORT_MEMBER_REMOVED';

export const CHANGE_COHORT_SUSPEND_STATUS = 'WNSW/COHORT_DETAILS/CHANGE_COHORT_SUSPEND_STATUS';
export const CHANGE_COHORT_SUSPEND_STATUS_SUCCESS = 'WNSW/COHORT_DETAILS/CHANGE_COHORT_SUSPEND_STATUS_SUCCESS';
export const CHANGE_COHORT_SUSPEND_STATUS_ERROR = 'WNSW/COHORT_DETAILS/CHANGE_COHORT_SUSPEND_STATUS_ERROR';
