/*
 * Cohort Details reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import {
  LOAD_COHORT_DETAILS,
  LOAD_COHORT_DETAILS_ERROR,
  LOAD_COHORT_DETAILS_SUCCESS,
  UPDATE_COHORT,
  CREATE_COHORT,
  CREATE_COHORT_SUCCESS,
  CREATE_COHORT_ERROR,
  SAVE_COHORT_SUCCESS,
  SAVE_COHORT_ERROR,
  SAVE_COHORT,
  RESET_COHORT,
  COHORT_MEMBER_REMOVED,
  CHANGE_COHORT_SUSPEND_STATUS_SUCCESS,
  CHANGE_COHORT_SUSPEND_STATUS_ERROR
} from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    group: {
      name: '',
      group_type: 'cohort',
      description: '',
    },
    members: [],
    suspended:false
  },
  groupTypes: ['cohort'],
});

function removeCohortMember(state, memberId, memberType) {
  const key = memberType === 'user' ? 'username' : 'id';
  const index = state.getIn(['data', 'members']).findIndex(m => m.getIn([memberType, key]) === memberId);
  return index >= 0 ? state.updateIn(['data', 'members'], members => members.delete(index)) : state;
}

function cohortDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_COHORT_DETAILS:
      return state.set('loading', true).set('error', false);
    case UPDATE_COHORT:
    case CHANGE_COHORT_SUSPEND_STATUS_SUCCESS:
      return state.setIn(['data', 'group', action.field], action.value);
    case LOAD_COHORT_DETAILS_SUCCESS:
      return state.set('data', fromJS(action.cohortDetails)).set('loading', false);
    case CREATE_COHORT:
    case SAVE_COHORT:
      return state.set('loading', true);
    case CREATE_COHORT_ERROR:
    case SAVE_COHORT_ERROR:
    case LOAD_COHORT_DETAILS_ERROR:
    case CHANGE_COHORT_SUSPEND_STATUS_ERROR:
      return state.set('error', action.error).set('loading', false);
    case CREATE_COHORT_SUCCESS:
    case SAVE_COHORT_SUCCESS:
      return state.set('loading', false);
    case RESET_COHORT:
      return state.set('data', initialState.get('data'));
    case COHORT_MEMBER_REMOVED:
      return removeCohortMember(state, action.memberId, action.memberType);
    default:
      return state;
  }
}

export default cohortDetailsReducer;
