/*
 * Content constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_CONTENT = 'WNSW/CONTENT/LOAD_CONTENT';
export const LOAD_CONTENT_SUCCESS = 'WNSW/CONTENT/LOAD_CONTENT_SUCCESS';
export const LOAD_CONTENT_ERROR = 'WNSW/CONTENT/LOAD_CONTENT_ERROR';

export const LOAD_MEASURE_DEFINITIONS = 'WNSW/CONTENT/LOAD_MEASURE_DEFINITIONS';
export const LOAD_MEASURE_DEFINITIONS_SUCCESS = 'WNSW/CONTENT/LOAD_MEASURE_DEFINITIONS_SUCCESS';
export const LOAD_MEASURE_DEFINITIONS_ERROR = 'WNSW/CONTENT/LOAD_MEASURE_DEFINITIONS_ERROR';

export const LOAD_CONTENT_MONTHS_DEFINITIONS = 'WNSW/CONTENT/LOAD_CONTENT_MONTHS_DEFINITIONS';
export const LOAD_CONTENT_MONTHS_SUCCESS = 'WNSW/CONTENT/LOAD_CONTENT_MONTHS_DEFINITIONS_SUCCESS';
export const LOAD_CONTENT_MONTHS_ERROR = 'WNSW/CONTENT/LOAD_CONTENT_MONTHS_DEFINITIONS_ERROR';
