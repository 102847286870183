import 'whatwg-fetch';
import { logError } from './errorLogging';

function getResponseBody(response) {
  const contentType = response.headers.get('content-type');
  const isJson = contentType && contentType.indexOf('application/json') !== -1;
  return isJson ? response.json() : response.text();
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return getResponseBody(response);
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  return getResponseBody(response).then(err => {
    if (typeof err === 'string') {
      try {
        err = JSON.parse(err);
      } catch (e) {
        // do nothing
      }
    }
    throw err;
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, { credentials: 'include', ...options })
    .then(checkStatus)
    .then(parseJSON)
    .catch(e => {
      if (url.substr(-3) !== '/me') {
        const errorMessage = `Request error: ${url} ${JSON.stringify(e)}`;
        logError(new Error(errorMessage), {});
      }
      throw e;
    });
}
