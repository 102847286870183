/*
 * Views Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import { Map } from 'immutable';
import {
  LOAD_VIEWS,
  LOAD_VIEWS_ERROR,
  LOAD_VIEWS_SUCCESS,
  RESET_SELECTED_VIEWS,
  TOGGLE_ALL_VIEWS,
  TOGGLE_VIEW_SELECTED
} from './constants';

/**
 * load views list
 *
 * @param  {object} params - filter parameters
 *
 * @return {object} An action object with a type of LOAD_VIEWS
 */
export function loadViews(params = Map()) {
  return {
    type: LOAD_VIEWS,
    params
  };
}

/**
 * Dispatched when the views list is loaded by the request saga
 *
 * @param  {array} views - views list
 *
 * @return {object} An action object with a type of LOAD_VIEWS_SUCCESS passing the views
 */
export function viewsLoaded(views) {
  return {
    type: LOAD_VIEWS_SUCCESS,
    views
  };
}

/**
 * Dispatched when loading fails
 *
 * @param  {object} error The error
 *
 * @return {object} An action object with a type of LOAD_VIEWS_ERROR passing the error
 */
export function viewsLoadingError(error) {
  return {
    type: LOAD_VIEWS_ERROR,
    error,
  };
}

/**
 * toggle selected flag on a view
 *
 *  @param  {int} index - view array key
 *  @param  {boolean} selected - new selected state
 *  @param  {string} viewId - selected view id
 *
 * @return {object} An action object with a type of TOGGLE_VIEW_SELECTED, index, selected and viewId properties
 */
export function toggleViewSelected(index, selected, viewId) {
  return {
    type: TOGGLE_VIEW_SELECTED,
    index,
    selected,
    viewId
  };
}

/**
 * toggle selected flag on all views
 *
 * @return {object} An action object with a type of TOGGLE_ALL_VIEWS
 */
export function selectAllViews(selected) {
  return {
    type: TOGGLE_ALL_VIEWS,
    selected
  };
}

/**
 * Reset selected views
 *
 * @return {object} An action object with a type of RESET_SELECTED_VIEWS
 */

export function resetSelectedViews() {
  return {
    type: RESET_SELECTED_VIEWS
  };
}
