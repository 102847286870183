import { call, put, takeLatest } from 'redux-saga/effects';
import { loadUser } from 'containers/App/actions';
import request from 'utils/request';
import { API_URL } from '../../App/constants';
import { SIGN_IN } from './constants';
import { signInError, signInSuccess } from './actions';

export function* signIn(action) {
  const requestURL = `${API_URL}/sign-in`;
  try {
    yield call(request, requestURL, { method: 'POST', body: JSON.stringify(action.data) });
    yield put(signInSuccess());
    yield put(loadUser());
  } catch (error) {
    const loginError =
      error === 'Unauthorized'
        ? 'Your username or password was wrong. Please try again!'
        : 'Unable to sign in at this time. Please try again later!';

    yield put(signInError(loginError));
  }
}

export default function* saga() {
  yield takeLatest(SIGN_IN, signIn);
}
