import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { fromJS } from 'immutable/dist/immutable';
import injectSaga from 'utils/injectSaga';
import viewsSaga from 'containers/Admin/Views/saga';
import { loadViews } from '../Admin/Views/actions';
import { toggleNavbar, signOut } from '../App/actions';
import {
  makeSelectNavbarExpanded,
  makeSelectLocation,
  makeSelectLoading,
  makeSelectIsAdmin,
  makeSelectUser,
} from '../App/selectors';
import { makeSelectViewsGroupedByDomain } from '../Admin/Views/selectors';
import AppFrame from './AppFrame';

const mapDispatchToProps = dispatch => ({
  onLoad: username => {
    const params = fromJS({ 'user.username': username });
    dispatch(loadViews(params));
  },
  toggleNavbar: expanded => {
    dispatch(toggleNavbar(expanded));
  },
  signOut: e => {
    e.preventDefault();
    dispatch(signOut());
  },
});

const mapStateToProps = createStructuredSelector({
  views: makeSelectViewsGroupedByDomain(),
  user: makeSelectUser(),
  navExpanded: makeSelectNavbarExpanded(),
  location: makeSelectLocation(),
  loading: makeSelectLoading(),
  isAdmin: makeSelectIsAdmin(),
});

const withSaga = injectSaga({ key: 'views', saga: viewsSaga });

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withErrorBoundary,
  withRouter,
  withSaga,
  withConnect
)(AppFrame);
