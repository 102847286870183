import { createSelector } from 'reselect';

const selectGlobal = state => state.get('global');

const selectContent = state => state.get('content');

const selectRoute = state => state.get('route');

const selectSnackBar = createSelector(selectGlobal, globalState => globalState.get('snackBar'));

const selectData = createSelector(selectGlobal, globalState => globalState.get('data'));

const makeSelectNavbarExpanded = () =>
  createSelector(selectGlobal, globalState => globalState.getIn(['navbar', 'expanded']));

const makeSelectLocation = () => createSelector(selectRoute, routeState => routeState.toJS());

const makeSelectUser = () => createSelector(selectData, globalState => globalState.get('user'));

const makeSelectLoading = () => createSelector(
  [selectGlobal, selectContent],
  (globalState, contentState) => globalState.get('loading') || contentState.get('loading')
);

const makeSelectIsOpenSnackBar = () => createSelector(selectSnackBar, state => state.get('isOpen'));

const makeSelectSnackBarMessage = () => createSelector(selectSnackBar, state => state.get('message'));

const makeSelectIsAdmin = () => state => state.getIn(['global', 'data', 'user', 'isAdmin']);

export {
  selectGlobal,
  makeSelectNavbarExpanded,
  makeSelectLocation,
  makeSelectUser,
  makeSelectLoading,
  makeSelectIsOpenSnackBar,
  makeSelectSnackBarMessage,
  makeSelectIsAdmin,
};
