import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute/Loadable';
import PublicRoute from 'containers/PublicRoute/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import Content from 'containers/Dashboard/Content/Loadable';
import Admin from 'containers/Admin/Loadable';
import Views from 'containers/Admin/Views/Loadable';
import ViewDetails from 'containers/Admin/Views/ViewDetails/Loadable';
import ViewGroups from 'containers/Admin/Views/ViewDetails/ViewGroups/Loadable';
import Facilities from 'containers/Admin/Facilities/Loadable';
import FacilityDetails from 'containers/Admin/Facilities/FacilityDetails/Loadable';
import FacilityGroups from 'containers/Admin/Facilities/FacilityDetails/FacilityGroups/Loadable';

import Groups from 'containers/Admin/Groups/Loadable';
import GroupManage from 'containers/Admin/Groups/GroupDetails/GroupManage/Loadable';
import GroupDetails from 'containers/Admin/Groups/GroupDetails/Loadable';
import GroupFacilities from 'containers/Admin/Groups/GroupDetails/GroupFacilities/Loadable';
import GroupUsers from 'containers/Admin/Groups/GroupDetails/GroupUsers/Loadable';
import GroupViews from 'containers/Admin/Groups/GroupDetails/GroupViews/Loadable';

import Cohorts from 'containers/Admin/Cohorts/Loadable';
import CohortManage from 'containers/Admin/Cohorts/CohortDetails/CohortManage/Loadable';
import CohortDetails from 'containers/Admin/Cohorts/CohortDetails/Loadable';
import CohortFacilities from 'containers/Admin/Cohorts/CohortDetails/CohortFacilities/Loadable';
import CohortViews from 'containers/Admin/Cohorts/CohortDetails/CohortViews/Loadable';

import Users from 'containers/Admin/Users/Loadable';
import UserManage from 'containers/Admin/Users/UserDetails/UserManage/Loadable';
import UserGroups from 'containers/Admin/Users/UserDetails/UserGroups/Loadable';
import UserDetails from 'containers/Admin/Users/UserDetails/Loadable';

import NotFoundPage from 'containers/NotFoundPage/Loadable';
import LoginFrame from 'components/Login/LoginFrame';

const loginRoutes = '/(|login|forgotPassword|resetPassword|activateAccount)/';
const loginRoutesExcludingRoot = '/(login|forgotPassword|resetPassword|activateAccount)/';

const Routes = ({ isLoggedIn }) => (
  <Switch>
    {!isLoggedIn ? (
      <Route exact path={loginRoutes} component={LoginFrame} />
    ) : (
      <Redirect exact path={loginRoutesExcludingRoot} to="/content/specific/V15" />
    )}
    <PrivateRoute exact path="/" component={Dashboard} />
    <PrivateRoute exact path="/content/:viewType/:viewId/measure/:measureId" component={Content} />
    <PrivateRoute exact path="/content/:viewType/:viewId" component={Content} />
    <PrivateRoute exact requiresAdmin path="/admin" component={Admin} />
    <PrivateRoute exact requiresAdmin path="/admin/groups" component={Groups} />
    <PrivateRoute exact requiresAdmin path="/admin/views" component={Views} />
    <PrivateRoute exact requiresAdmin path="/admin/views/details/:viewId/" component={ViewDetails} />
    <PrivateRoute exact requiresAdmin path="/admin/views/details/:viewId/:viewType/groups" component={ViewGroups} />
    <PrivateRoute exact requiresAdmin path="/admin/groups/manage/:groupId?" component={GroupManage} />
    <PrivateRoute exact requiresAdmin path="/admin/groups/details/:groupId" component={GroupDetails} />
    <PrivateRoute exact requiresAdmin path="/admin/groups/details/:groupId/facilities/:isEdit?" component={GroupFacilities} />
    <PrivateRoute exact requiresAdmin path="/admin/groups/details/:groupId/views/:isEdit?" component={GroupViews} />
    <PrivateRoute exact requiresAdmin path="/admin/groups/details/:groupId/users/:isEdit?" component={GroupUsers} />
    <PrivateRoute exact requiresAdmin path="/admin/users" component={Users} />
    <PrivateRoute exact requiresAdmin path="/admin/users/manage/:userId?" component={UserManage} />
    <PrivateRoute exact requiresAdmin path="/admin/users/details/:userId?" component={UserDetails} />
    <PrivateRoute exact requiresAdmin path="/admin/users/details/:userId/groups" component={UserGroups} />
    <PrivateRoute exact requiresAdmin path="/admin/facilities/details/:facilityId" component={FacilityDetails} />
    <PrivateRoute exact requiresAdmin path="/admin/facilities/details/:facilityId/groups" component={FacilityGroups} />
    <PrivateRoute exact requiresAdmin path="/admin/facilities" component={Facilities} />

    <PrivateRoute exact requiresAdmin path="/admin/cohorts" component={Cohorts} />
    <PrivateRoute exact requiresAdmin path="/admin/cohorts/manage/:cohortId?" component={CohortManage} />
    <PrivateRoute exact requiresAdmin path="/admin/cohorts/details/:cohortId" component={CohortDetails} />
    <PrivateRoute exact requiresAdmin path="/admin/cohorts/details/:cohortId/facilities/:isEdit?" component={CohortFacilities} />
    <PrivateRoute exact requiresAdmin path="/admin/cohorts/details/:cohortId/views/:isEdit?" component={CohortViews} />

    <PublicRoute path="" component={NotFoundPage} />
  </Switch>
);

Routes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  // user: PropTypes.object,
};

export default withRouter(Routes);
