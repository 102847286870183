import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import './style.scss';

const ErrorPage = ({ feedbackFunc }) => (
  <div className="error-page">
    <div className="error-title">It looks like you&apos;ve encountered an error.</div>
    <div className="error-description">
      <br />
      We&apos;ve automatically logged the issue
      {feedbackFunc ? (
        <span>
          , but please feel free to provide more detail:
          <div className="feedback-button">
            <Button onClick={feedbackFunc} variant="raised" color="primary">
              Provide More Detail
            </Button>
          </div>
        </span>
      ) : (
        <span> &nbsp;.</span>
      )}
      If you keep having trouble please contact: <br />
      <a href={'mailto:wnsw-phn@synergia.app'}>wnsw-phn@synergia.app</a>
      <br />
      <br />
      Otherwise, the link below will take you back to the home page
    </div>

    <Link to="/">
      <Button className="rounded-button MuiButton-tertiary" color="secondary">
        Back to Home
      </Button>
    </Link>
  </div>
);

ErrorPage.propTypes = {
  feedbackFunc: PropTypes.func,
};

export default ErrorPage;
