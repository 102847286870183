import request from 'utils/request';
import { call, put, takeLatest } from 'redux-saga/effects';
import { isIE } from 'utils/browser';
import { LOAD_USER, SIGN_OUT, API_URL } from './constants';
import { userLoaded, userLoadingError, signedOut, signOutError } from './actions';

export function* getUser() {
  let requestURL = `${API_URL}/me`;
  if (isIE()) {
    requestURL += `?time=${new Date().getTime()}`; // Hack to prevent request caching.
  }

  try {
    const user = yield call(request, requestURL);
    yield put(userLoaded(user));
  } catch (err) {
    yield put(userLoadingError(err));
  }
}

export function* signOut() {
  const requestURL = `${API_URL}/sign-out`;

  try {
    yield call(request, requestURL, { method: 'POST' });
    yield put(signedOut());
  } catch (err) {
    yield put(signOutError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* userData() {
  yield takeLatest(LOAD_USER, getUser);
  yield takeLatest(SIGN_OUT, signOut);
}
