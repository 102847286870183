import React from 'react';
import { Helmet } from 'react-helmet';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import classNames from 'classnames';
import { isIE } from 'utils/browser';
import AppFrame from 'containers/AppFrame';
import ConfirmDialog from 'containers/Dialog/ConfirmDialog';
import Routes from './Routes';
import { theme } from './appTheme';
import './style.scss';

class App extends React.PureComponent {
  componentWillMount() {
    if (!this.props.user) {
      this.props.loadUser();
    }
  }

  render() {
    const { user, isOpenSnackBar, closeSnackBar, snackBarMessage } = this.props;
    const isLoggedIn = user && user.get('username');
    const isAdmin = user && user.get('isAdmin');

    if (!user) {
      return ''
    }

    return (
      <MuiThemeProvider theme={theme}>
        <Helmet titleTemplate="%s - WNSW PHN" defaultTitle="WNSW PHN">
          <meta name="description" content="WNSW PHN" />
        </Helmet>
        <div className={classNames('root', isIE() && 'internet-explorer')}>
          {!isLoggedIn && <Routes isLoggedIn={false} isAdmin={false} />}
          {isLoggedIn && (
            <AppFrame>
              <Routes isLoggedIn isAdmin={isAdmin} user={user} />
            </AppFrame>
          )}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isOpenSnackBar}
            onClose={closeSnackBar}
            ContentProps={{ 'aria-describedby': 'message-id' }}
            message={snackBarMessage}
          />
          <ConfirmDialog />
        </div>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  user: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.bool]).isRequired,
  loadUser: PropTypes.func.isRequired,
  isOpenSnackBar: PropTypes.bool.isRequired,
  closeSnackBar: PropTypes.func.isRequired,
  snackBarMessage: PropTypes.string.isRequired,
};

export default App;
