/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form/immutable';
import globalReducer from 'containers/App/reducer';
import contentReducer from 'containers/Dashboard/Content/reducer';
import groupsReducer from 'containers/Admin/Groups/reducer';
import cohortsReducer from 'containers/Admin/Cohorts/reducer';
import viewsReducer from 'containers/Admin/Views/reducer';
import viewDetailsReducer from 'containers/Admin/Views/ViewDetails/reducer';
import usersReducer from 'containers/Admin/Users/reducer';
import groupDetailsReducer from 'containers/Admin/Groups/GroupDetails/reducer';
import cohortDetailsReducer from 'containers/Admin/Cohorts/CohortDetails/reducer';
import facilityDetailsReducer from 'containers/Admin/Facilities/FacilityDetails/reducer';
import userDetailsReducer from 'containers/Admin/Users/UserDetails/reducer';
import facilitiesReducer from 'containers/Admin/Facilities/reducer';
import adminReducer from 'containers/Admin/reducer';
import loginFormReducer from 'containers/Login/LoginForm/reducer';
import forgotPasswordFormReducer from 'containers/Login/ForgotPasswordForm/reducer';
import changePasswordFormReducer from 'containers/Login/ChangePasswordForm/reducer';
import dialogReducer from 'containers/Dialog/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    route: routeReducer,
    global: globalReducer,
    admin: adminReducer,
    groups: groupsReducer,
    cohorts: cohortsReducer,
    views: viewsReducer,
    users: usersReducer,
    facilities: facilitiesReducer,
    groupDetails: groupDetailsReducer,
    cohortDetails: cohortDetailsReducer,
    viewDetails: viewDetailsReducer,
    userDetails: userDetailsReducer,
    facilityDetails: facilityDetailsReducer,
    content: contentReducer,
    loginForm: loginFormReducer,
    forgotPasswordForm: forgotPasswordFormReducer,
    changePasswordForm: changePasswordFormReducer,
    dialog: dialogReducer,
    form: formReducer, // Form data, managed by redux-form.
    ...injectedReducers,
  });
}
