import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from 'components/UserMenu';
import LinearProgress from '@material-ui/core/LinearProgress';
import './style.scss';
import logo from './images/phn-logo-white-top.png';

const MenuBar = ({ isAdmin, signOut, expandNavBar, loading }) => (
  <div className="menu-bar">
    <div className="menu-bar-waves" />
    <IconButton className="menu-icon-button" aria-label="Menu" onClick={expandNavBar}>
      <MenuIcon />
    </IconButton>
    <div className="phn-logo-top">
      <img alt="phn-logo" src={logo} />
    </div>
    <div className="phn-logo-bottom" />
    <div className="menu-bar-title">Primary Health Information Platform</div>
    <UserMenu isAdmin={isAdmin} signOut={signOut} />
    {loading && <LinearProgress className="menu-bar-loader" color={'secondary'} />}
  </div>
);

MenuBar.propTypes = {
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  expandNavBar: PropTypes.func.isRequired,
};

export default MenuBar;
