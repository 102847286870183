/*
 * Groups reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import { LOAD_GROUPS, LOAD_GROUPS_ERROR, LOAD_GROUPS_SUCCESS, TOGGLE_GROUP_SELECTED, RESET_SELECTED_GROUPS } from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    list: [],
    args: {},
    total: 0
  },
  selectedGroupIds: []
});

function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_GROUPS:
      return state
        .set('loading', true)
        .set('error', false);
    case LOAD_GROUPS_SUCCESS:
      return state
        .set('data', fromJS(action.data))
        .set('loading', false)
        .updateIn(['data', 'list'], (list) =>
          list.map((group) => {
            let tempGroup = group;
            state.get('selectedGroupIds').forEach((id) => {
              if (id === group.get('id')) {
                tempGroup = group.set('selected', true);
              }
            });
            return tempGroup;
          })
        );
    case LOAD_GROUPS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false);
    case RESET_SELECTED_GROUPS:
      return state
        .set('selectedGroupIds', initialState.get('selectedGroupIds'));
    case TOGGLE_GROUP_SELECTED:
      return state
        .setIn(['data', 'list', action.index, 'selected'], action.selected)
        .updateIn(['selectedGroupIds'], (selectedGroupIds) => {
          if (action.selected) {
            return selectedGroupIds.concat(action.groupId);
          }
          return selectedGroupIds.filter((id) => id !== action.groupId);
        });
    default:
      return state;
  }
}

export default groupsReducer;
