import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

// This should be used as the 'component' prop passed to redux-form's 'Field' component.
const FormTextField = ({ input, meta: { error, touched }, ...rest }) => {
  const showError = touched && !!error;
  return <TextField {...input} error={showError} helperText={showError ? error : ' '} {...rest} />;
};

FormTextField.propTypes = {
  // The input and meta props are provided by redux-form.
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default FormTextField;
