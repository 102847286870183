import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NavBar from 'components/NavBar';
import MenuBar from 'containers/MenuBar';
import Disclaimer from 'components/Disclaimer';
import Footer from 'components/Footer';
import { isMobile } from 'utils/mobile';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { Map, OrderedMap } from 'immutable/dist/immutable';
import './style.scss';
import { lookupDomainIcon } from '../../components/ViewCard/icons';
import LumioIcon from '../../components/LumioIcon/LumioIcon';

class AppFrame extends PureComponent {
  state = { menuBarExpanded: true, navBarExpanded: false };

  componentWillMount() {
    const { user, onLoad } = this.props;
    if (user) {
      onLoad(user.get('username'));
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  handleWindowScroll = () => {
    this.setState({ menuBarExpanded: this.shouldExpandMenuBar() });
  };

  expandNavBar = () => {
    this.setState({ navBarExpanded: true });
  };

  collapseNavBar = () => {
    this.setState({ navBarExpanded: false });
  };

  collapseNavBarIfMobile = () => {
    if (isMobile()) {
      this.collapseNavBar();
    }
  };

  toggleNavBarExpanded = () => {
    this.setState({ navBarExpanded: !this.state.navBarExpanded });
  };

  shouldExpandMenuBar() {
    const viewportHeight = window.innerHeight;
    const contentHeight = this.contentElement.clientHeight;
    const totalHeight = contentHeight + 130; // Menu + footer height should be at least 130.
    if (totalHeight > viewportHeight) {
      // IMPORTANT: Only collapse menu bar if vertical scrollbar will still be visible *after* the collapse.
      return window.scrollY === 0;
    }
    return true;
  }

  nav() {
    const { views, isAdmin } = this.props;
    const result = [];
    result.push({ to: '/', label: 'Home', icon: <HomeIcon /> });
    views.forEach(viewsInCategory => {
      viewsInCategory.forEach(view => {
        if (view.get('view_type') === 'specific') {
          result.push({
            to: `/content/${view.get('view_type')}/${view.get('id')}`,
            label: view.get('name'),
            icon: <LumioIcon icon={lookupDomainIcon(view.get('view_domain'))} />,
          });
        }
      });
    });
    if (isAdmin) {
      result.push({ to: '/admin', label: 'Admin', icon: <AssignmentIndIcon /> });
    }
    return result;
  }

  render() {
    const { loading, children, isAdmin, location } = this.props;
    const { menuBarExpanded, navBarExpanded } = this.state;
    return (
      <div
        className={classNames(
          'app-frame',
          menuBarExpanded && location.pathname === '/' && 'menu-bar-expanded',
          location.pathname === '/' && 'app-home-page',
          navBarExpanded && 'nav-bar-expanded'
        )}
      >
        <MenuBar loading={loading} isAdmin={isAdmin} expandNavBar={this.expandNavBar} />
        <NavBar
          nav={this.nav()}
          expanded={this.state.navBarExpanded}
          collapse={this.collapseNavBarIfMobile}
          toggleExpanded={this.toggleNavBarExpanded}
          isAdmin={isAdmin}
        />
        <div className="scroll-content">
          <main
            className="content"
            ref={e => {
              this.contentElement = e;
            }}
          >
            {children}
          </main>
          <Disclaimer />
          <Footer />
        </div>
        <button className="nav-bar-background" onClick={this.collapseNavBar} />
      </div>
    );
  }
}

AppFrame.propTypes = {
  loading: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  views: PropTypes.instanceOf(OrderedMap).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired, // Provided by react-router.
  children: PropTypes.object.isRequired,
};

export default withRouter(AppFrame);
