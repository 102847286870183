import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from 'utils/injectSaga';
import { loadUser, updateSnackBar } from './actions';
import { makeSelectUser, makeSelectIsOpenSnackBar, makeSelectSnackBarMessage } from './selectors';
import saga from './saga';
import App from './App';

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  isOpenSnackBar: makeSelectIsOpenSnackBar(),
  snackBarMessage: makeSelectSnackBarMessage(),
});

const mapDispatchToProps = dispatch => ({
  loadUser: () => {
    dispatch(loadUser());
  },
  closeSnackBar: () => {
    dispatch(updateSnackBar({ isOpen: false }));
  },
});

const withSaga = injectSaga({ key: 'global', saga });
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withSaga,
  withConnect
)(App);
export { mapDispatchToProps };
