import { INITIALIZE, SIGN_IN, SIGN_IN_ERROR, SIGN_IN_SUCCESS } from './constants';

export const initialize = () => ({ type: INITIALIZE });

export const signIn = (email, password) => ({
  type: SIGN_IN,
  data: {
    email,
    password,
  },
});

export const signInError = error => ({
  type: SIGN_IN_ERROR,
  error,
});

export const signInSuccess = () => ({ type: SIGN_IN_SUCCESS });
