/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const TOGGLE_NAVBAR = 'wnsw/App/TOGGLE_NAVBAR';
export const UPDATE_SNACK_BAR = 'wnsw/App/UPDATE_SNACK_BAR';
export const LOAD_USER = 'wnsw/App/LOAD_USER';
export const LOAD_USER_SUCCESS = 'wnsw/App/LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'wnsw/App/LOAD_USER_ERROR';

export const SIGN_OUT = 'wnsw/App/SIGN_OUT';
export const SIGN_OUT_ERROR = 'wnsw/App/SIGN_OUT_ERROR';
export const SIGN_OUT_SUCCESS = 'wnsw/App/SIGN_OUT_SUCCESS';

export const PRINT_START = 'wnsw/App/PRINT_START';
export const PRINT_FINISH = 'wnsw/App/PRINT_FINISH';

export const API_URL = process.env.NODE_ENV === 'development' ? 'https://dev.wnsw.synergia.nz/api/v3' : `${window.location.origin}/api/v3`;
export const drawerWidth = 240;
