import { connect } from 'react-redux';
import { signOut } from '../App/actions';
import MenuBar from './MenuBar';

const mapDispatchToProps = dispatch => ({
  signOut: () => {
    dispatch(signOut());
  },
});

export default connect(
  null,
  mapDispatchToProps
)(MenuBar);
