import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logError, logFeedback } from '../../utils/errorLogging';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    // const { history } = this.props;
    //
    // // Reset error state if transitioning to another location
    // history.listen(() => {
    //   console.log('--------------------------------->HISTORY CHECK FOR ERROR<--------------------------------------------');
    //   if (this.state.hasError) {
    //     this.setState({
    //       hasError: false,
    //     });
    //   }
    // });
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    logError(error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    const { children, fallback: Fallback = ErrorPage } = this.props;
    if (this.state.hasError) {
      return <Fallback feedbackFunc={logFeedback} />;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.func,
  // history: PropTypes.object.isRequired, // Provided by react-router.
};

/*eslint-disable */
export const withErrorBoundary = (ComponentThatMayError, FallbackComponent, onError) => props => (
  <ErrorBoundary fallback={FallbackComponent} onError={onError}>
    <ComponentThatMayError {...props} />
  </ErrorBoundary>
);
/* eslint-enable */

// export default withRouter(ErrorBoundary);
export default ErrorBoundary;
