/*
 * Admin Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const UPDATE_FILTER = 'WNSW/ADMIN/UPDATE_FILTER';
export const RESET_FILTERS = 'WNSW/ADMIN/RESET_FILTERS';
export const SET_FILTERS = 'WNSW/ADMIN/SET_FILTERS';
