import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import saga from './saga';
import { changePassword } from './actions';
import ChangePasswordForm from './ChangePasswordForm';

// Note: toJS() creates new objects each time, but we can use it here because there are no object props, only primitives.
const mapStateToProps = (state, props) => ({
  ...state.get('changePasswordForm').toJS(),
  ...props,
});

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: values => {
    const { email, newPassword } = values.toJS();
    const urlParams = new URLSearchParams(props.location.search);
    const resetToken = urlParams && urlParams.get('resetToken');
    dispatch(changePassword(email, newPassword, resetToken));
  },
});

const withSaga = injectSaga({ key: 'changePasswordForm', saga });
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withSaga,
  withConnect
)(ChangePasswordForm);
