/*
 * Users reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import {
  LOAD_USERS,
  LOAD_USERS_ERROR,
  LOAD_USERS_SUCCESS,
  TOGGLE_ALL_USERS,
  TOGGLE_USER_SELECTED,
  RESET_SELECTED_USERS
} from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    list: [],
    args: {},
    total: 0
  },
  selectedUserNames: []
});

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USERS:
      return state
        .set('loading', true)
        .set('error', false);
    case LOAD_USERS_SUCCESS:
      return state
        .set('data', fromJS(action.data))
        .set('loading', false)
        .updateIn(['data', 'list'], (list) =>
          list.map((user) => {
            let tempUser = user;
            state.get('selectedUserNames').forEach((username) => {
              if (username === user.get('username')) {
                tempUser = user.set('selected', true);
              }
            });
            return tempUser;
          })
        );
    case LOAD_USERS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false);
    case RESET_SELECTED_USERS:
      return state
        .set('selectedUserNames', initialState.get('selectedUserNames'));
    case TOGGLE_USER_SELECTED:
      return state
        .setIn(['data', 'list', action.index, 'selected'], action.selected)
        .updateIn(['selectedUserNames'], (selectedUserNames) => {
          if (action.selected) {
            return selectedUserNames.concat(action.username);
          }
          return selectedUserNames.filter((id) => id !== action.username);
        });
    case TOGGLE_ALL_USERS:
      return state
        .updateIn(['data', 'list'], (users) => users.map((user) => user.set('selected', action.selected)))
        .update('selectedUserNames', (selectedUserNames) => {
          const list = state.getIn(['data', 'list']);
          if (action.selected) {
            return selectedUserNames.concat(list.map((user) => user.get('username'))).toSet().toList();
          }
          return selectedUserNames.filter((username) => !list.find((user) => user.get('username') === username));
        });
    default:
      return state;
  }
}

export default usersReducer;
