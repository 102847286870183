import { connect } from 'react-redux';
import * as constants from '../constants';
import * as actions from '../actions';
import ConfirmDialog from './ConfirmDialog';

const mapStateToProps = state => ({
  title: state.getIn(['dialog', 'data', 'title']),
  description: state.getIn(['dialog', 'data', 'description']),
  confirmButtonText: state.getIn(['dialog', 'data', 'confirmButtonText']),
  onConfirm: state.getIn(['dialog', 'data', 'onConfirm']),
});

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(actions.closeDialog(constants.CONFIRM_DIALOG_KEY)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmDialog);
