import React from 'react';
import PropTypes from 'prop-types';
import { Dialog as MaterialDialog, Slide } from '@material-ui/core';

const Transition = props => <Slide direction="up" {...props} />;

const Dialog = ({ open, handleClose, children, dialogKey, ...rest }) => (
  <MaterialDialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} {...rest}>
    {children}
  </MaterialDialog>
);

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  dialogKey: PropTypes.string.isRequired,
};

export default Dialog;
