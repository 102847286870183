/*
 * Views reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import {
  LOAD_VIEWS,
  LOAD_VIEWS_ERROR,
  LOAD_VIEWS_SUCCESS,
  TOGGLE_ALL_VIEWS,
  TOGGLE_VIEW_SELECTED,
  RESET_SELECTED_VIEWS
} from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    list: [],
    args: {},
    total: 0
  },
  selectedViewIds: []
});

function viewsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_VIEWS:
      return state
        .set('loading', true)
        .set('error', false);
    case LOAD_VIEWS_SUCCESS:
      return state
        .set('data', fromJS(action.views))
        .set('loading', false)
        .updateIn(['data', 'list'], (list) =>
          list.map((view) => {
            let tempView = view;
            state.get('selectedViewIds').forEach((id) => {
              if (id === view.get('id')) {
                tempView = view.set('selected', true);
              }
            });
            return tempView;
          })
        );
    case LOAD_VIEWS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false);
    case TOGGLE_VIEW_SELECTED:
      return state
        .setIn(['data', 'list', action.index, 'selected'], action.selected)
        .updateIn(['selectedViewIds'], (selectedViewIds) => {
          if (action.selected) {
            return selectedViewIds.concat(action.viewId);
          }
          return selectedViewIds.filter((id) => id !== action.viewId);
        });
    case RESET_SELECTED_VIEWS:
      return state
        .set('selectedViewIds', initialState.get('selectedViewIds'));
    case TOGGLE_ALL_VIEWS:
      return state
        .updateIn(['data', 'list'], (views) => views.map((view) => view.set('selected', action.selected)))
        .update('selectedViewIds', (selectedViewIds) => {
          const list = state.getIn(['data', 'list']);
          if (action.selected) {
            return selectedViewIds.concat(list.map((view) => view.get('id'))).toSet().toList();
          }
          return selectedViewIds.filter((id) => !list.find((view) => view.get('id') === id));
        });
    default:
      return state;
  }
}

export default viewsReducer;
