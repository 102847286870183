/*
 * Content Details reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import {
  LOAD_VIEW_DETAILS,
  LOAD_VIEW_DETAILS_ERROR,
  LOAD_VIEW_DETAILS_SUCCESS,
  RESET_VIEW,
  VIEW_REMOVED_FROM_GROUP,
  VIEW_REMOVED_FROM_COHORT,
  SET_VIEW_COHORTS_SORT_SUCCESS
} from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    view: {},
    groups: [],
  },
});

function removeViewFromGroup(state, groupId) {
  const index = state.getIn(['data', 'groups']).findIndex(group => group.get('id') === groupId);
  return index >= 0 ? state.updateIn(['data', 'groups'], groups => groups.delete(index)) : state;
}

function removeViewFromCohort(state, cohortId) {
  const index = state.getIn(['data', 'cohorts']).findIndex(group => group.get('id') === cohortId);
  return index >= 0 ? state.updateIn(['data', 'cohorts'], groups => groups.delete(index)) : state;
}

function viewDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_VIEW_DETAILS:
      return state.set('loading', true).set('error', false);
    case LOAD_VIEW_DETAILS_SUCCESS:
      return state.set('data', fromJS(action.viewDetails)).set('loading', false);
    case SET_VIEW_COHORTS_SORT_SUCCESS:
      return state.setIn(['data', 'cohorts'], fromJS(action.cohorts));
    case LOAD_VIEW_DETAILS_ERROR:
      return state.set('error', action.error).set('loading', false);
    case RESET_VIEW:
      return state.set('data', initialState.get('data'));
    case VIEW_REMOVED_FROM_GROUP:
      return removeViewFromGroup(state, action.groupId);
    case VIEW_REMOVED_FROM_COHORT:
      return removeViewFromCohort(state, action.cohortId);
    default:
      return state;
  }
}

export default viewDetailsReducer;
