import React from 'react';
import './style.scss';

const Footer = () => (
  <footer>
    <div className="footer-container">
      <div className="no-print">
        {/* <ul>
          <li>
            <a href="#">About Analytics</a>
          </li>
          <li>|</li>
          <li>
            <a href="#">About Synergia</a>
          </li>
          <li>|</li>
          <li>
            <a href="#">Terms of use</a>
          </li>
          <li>|</li>
          <li>
            <a href="#">Contact us</a>
          </li>
        </ul> */}
      </div>
      <p className='copyright'>
        All data and information are the property of Western NSW PHN &copy; 2018–{new Date().getFullYear()}. All rights reserved.
        <br />
        All graphics, text, designs & images are the property of Synergia &copy; 2018–{new Date().getFullYear()}. All Rights Reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
