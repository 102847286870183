import { Map } from 'immutable';
import * as constants from './constants';

const reducer = (state = Map({ key: null, data: null }), action) => {
  switch (action.type) {
    case constants.OPEN_DIALOG:
      return Map({ key: action.key, data: action.data });
    case constants.CLOSE_DIALOG:
      return state.set('key', null);
    default:
      return state;
  }
};

export default reducer;
