/*
 * Cohorts reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import { LOAD_COHORTS, LOAD_COHORTS_ERROR, LOAD_COHORTS_SUCCESS, TOGGLE_COHORT_SELECTED, RESET_SELECTED_COHORTS } from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    list: [],
    args: {},
    total: 0
  },
  selectedCohortIds: []
});

function cohortsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_COHORTS:
      return state
        .set('loading', true)
        .set('error', false);
    case LOAD_COHORTS_SUCCESS:
      return state
        .set('data', fromJS(action.data))
        .set('loading', false)
        .updateIn(['data', 'list'], (list) =>
          list.map((cohort) => {
            let tempCohort = cohort;
            state.get('selectedCohortIds').forEach((id) => {
              if (id === cohort.get('id')) {
                tempCohort = cohort.set('selected', true);
              }
            });
            return tempCohort;
          })
        );
    case LOAD_COHORTS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false);
    case RESET_SELECTED_COHORTS:
      return state
        .set('selectedCohortIds', initialState.get('selectedCohortIds'));
    case TOGGLE_COHORT_SELECTED:
      return state
        .setIn(['data', 'list', action.index, 'selected'], action.selected)
        .updateIn(['selectedCohortIds'], (selectedCohortIds) => {
          if (action.selected) {
            return selectedCohortIds.concat(action.cohortId);
          }
          return selectedCohortIds.filter((id) => id !== action.cohortId);
        });
    default:
      return state;
  }
}

export default cohortsReducer;
