/*
 * Facility Details Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_FACILITY_DETAILS = 'WNSW/FACILITY_DETAILS/LOAD_FACILITY_DETAILS';
export const LOAD_FACILITY_DETAILS_SUCCESS = 'WNSW/FACILITY_DETAILS/LOAD_FACILITY_DETAILS_SUCCESS';
export const LOAD_FACILITY_DETAILS_ERROR = 'WNSW/FACILITY_DETAILS/LOAD_FACILITY_DETAILS_ERROR';

export const SET_FACILITY_GROUPS = 'WNSW/FACILITY_DETAILS/SET_FACILITY_GROUPS';
export const SET_FACILITY_GROUPS_SUCCESS = 'WNSW/FACILITY_DETAILS/SET_FACILITY_GROUPS_SUCCESS';
export const SET_FACILITY_GROUPS_ERROR = 'WNSW/FACILITY_DETAILS/SET_FACILITY_GROUPS_ERROR';

export const RESET_FACILITY = 'WNSW/FACILITY_DETAILS/RESET_FACILITY';

export const REMOVE_FACILITY_FROM_GROUP = 'WNSW/FACILITY_DETAILS/REMOVE_FACILITY_FROM_GROUP';
export const FACILITY_REMOVED_FROM_GROUP = 'WNSW/FACILITY_DETAILS/FACILITY_REMOVED_FROM_GROUP';

export const REMOVE_FACILITY_FROM_COHORT = 'WNSW/FACILITY_DETAILS/REMOVE_FACILITY_FROM_COHORT';
export const FACILITY_REMOVED_FROM_COHORT = 'WNSW/FACILITY_DETAILS/FACILITY_REMOVED_FROM_COHORT';
