/*
 * Facility Details reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import {
  LOAD_FACILITY_DETAILS,
  LOAD_FACILITY_DETAILS_ERROR,
  LOAD_FACILITY_DETAILS_SUCCESS,
  RESET_FACILITY,
  FACILITY_REMOVED_FROM_GROUP,
  FACILITY_REMOVED_FROM_COHORT
} from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    facility: {
      id: 1,
      name: 'Facility name 1',
      address: '105 Queen street',
    },
    cohorts: [],
    groups: [
      {
        name: 'Group name',
        group_type: 'general',
      },
      {
        name: 'Group name 2',
        group_type: 'admin',
      },
    ],
  },
});

function removeFacilityFromGroup(state, groupId) {
  const index = state.getIn(['data', 'groups']).findIndex(group => group.get('id') === groupId);
  return index >= 0 ? state.updateIn(['data', 'groups'], groups => groups.delete(index)) : state;
}

function removeFacilityFromCohort(state, cohortId) {
  const index = state.getIn(['data', 'cohorts']).findIndex(c => c.get('id') === cohortId);
  return index >= 0 ? state.updateIn(['data', 'cohorts'], cohorts => cohorts.delete(index)) : state;
}

function facilityDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FACILITY_DETAILS:
      return state.set('loading', true).set('error', false);
    case LOAD_FACILITY_DETAILS_SUCCESS:
      return state.set('data', fromJS(action.facilityDetails)).set('loading', false);
    case LOAD_FACILITY_DETAILS_ERROR:
      return state.set('error', action.error).set('loading', false);
    case RESET_FACILITY:
      return state.set('data', initialState.get('data'));
    case FACILITY_REMOVED_FROM_GROUP:
      return removeFacilityFromGroup(state, action.groupId);
    case FACILITY_REMOVED_FROM_COHORT:
      return removeFacilityFromCohort(state, action.cohortId);
    default:
      return state;
  }
}

export default facilityDetailsReducer;
