import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import saga from './saga';
import { initialize, sendPasswordReset } from './actions';
import ForgotPasswordForm from './ForgotPasswordForm';

// Note: toJS() creates new objects each time, but we can use it here because there are no object props, only primitives.
const mapStateToProps = state => state.get('forgotPasswordForm').toJS();

const mapDispatchToProps = dispatch => ({
  onComponentAboutToUnmount: () => dispatch(initialize()),
  onSubmit: values => dispatch(sendPasswordReset(values.get('email'))),
});

const withSaga = injectSaga({ key: 'forgotPasswordForm', saga });
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withSaga,
  withConnect
)(ForgotPasswordForm);
