/*
 * Content reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import {
  LOAD_CONTENT_SUCCESS,
  LOAD_CONTENT_ERROR,
  LOAD_CONTENT,
  LOAD_MEASURE_DEFINITIONS_SUCCESS,
  LOAD_MEASURE_DEFINITIONS,
  LOAD_MEASURE_DEFINITIONS_ERROR,
  LOAD_CONTENT_MONTHS_DEFINITIONS,
  LOAD_CONTENT_MONTHS_SUCCESS,
  LOAD_CONTENT_MONTHS_ERROR,
} from './constants';

const blankData = {
  measure_averages: {},
  facility_measures: [],
  view: {
    measure_ids: [],
  },
};

const initialState = fromJS({
  loading: false,
  error: false,
  data: blankData,
  definitions: [],
  content_months: [],
});

function contentReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_CONTENT:
    case LOAD_MEASURE_DEFINITIONS:
    case LOAD_CONTENT_MONTHS_DEFINITIONS:
      return state.set('loading', true).set('error', false);
    case LOAD_CONTENT_SUCCESS:
      return state.set('data', fromJS(action.data)).set('loading', false);
    case LOAD_MEASURE_DEFINITIONS_SUCCESS:
      return state.set('definitions', fromJS(action.data.definitions)).set('loading', false);
    case LOAD_CONTENT_MONTHS_SUCCESS:
      return state.set('content_months', fromJS(action.data.available_months.slice(0, 6))).set('loading', false);
    case LOAD_CONTENT_ERROR:
    case LOAD_MEASURE_DEFINITIONS_ERROR:
    case LOAD_CONTENT_MONTHS_ERROR:
      return state
        .set('error', action.error)
        .set('loading', false)
        .set('data', fromJS(blankData));
    default:
      return state;
  }
}

export default contentReducer;
