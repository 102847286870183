import React from 'react';
import './style.scss';

const Disclaimer = () => (
  <div className='disclaimer'>
    <p>
      While the Australian Government helped fund this platform, they have not reviewed the content and are not responsible for any injury, loss or damage that may arise from the use of or reliance on the information provided herein.
      <br />
      This data report is provided “as is” without warranty of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose and noninfringement.
      In no event shall the authors or copyright holders be liable for any form, damages or other liability, whether in an action of contract, tort or otherwise, arising from out of or in connection with the data report provided or the use or other dealings with the data report.
    </p>
  </div>
);

export default Disclaimer;
