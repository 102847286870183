const order = [
  'PIP QI',
  'ODGP',
  'Population Health & Screening',
  'Chronic Disease Management',
  'Diabetes',
  'Cardiovascular Disease',
  'Respiratory',
  'Mental Health',
  'Aged Care',
  'Aboriginal Medical Services',
];

export default order;
