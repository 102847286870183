/*
 * Cohorts Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_COHORTS = 'WNSW/COHORTS/LOAD_COHORTS';
export const LOAD_COHORTS_SUCCESS = 'WNSW/COHORTS/LOAD_COHORTS_SUCCESS';
export const LOAD_COHORTS_ERROR = 'WNSW/COHORTS/LOAD_COHORTS_ERROR';
export const TOGGLE_COHORT_SELECTED = 'WNSW/COHORTS/TOGGLE_COHORT_SELECTED';
export const RESET_SELECTED_COHORTS = 'WNSW/COHORTS/RESET_SELECTED_COHORTS';
