import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import App from 'containers/App';
import ScrollToTop from "containers/ScrollToTop";
import BrowserSupport from "components/BrowserSupport";
import UnsupportedBrowser from "containers/UnsupportedBrowser/Loadable";
import configureStore from "configureStore";
import errorLogging from "utils/errorLogging";
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import 'sanitize.css/sanitize.css';
import 'styles/theme.scss';

const initialState = {};
const history = createHistory();
const store = configureStore(initialState, history);

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-129098365-1');
  history.listen(location => ReactGA.pageview(location.pathname + location.search));
}

// Init Error logging
errorLogging();

ReactDOM.render(<Provider store={store}>
  {/* <LanguageProvider messages={messages}> */}
  <ConnectedRouter history={history}>
    <ScrollToTop>
      <BrowserSupport
        redirectUrl={'/unsupported-browser'}
        minBrowsers={[
          {
            name: 'IE',
            version: 11,
          },
          {
            name: 'Chrome',
            version: 50,
          },
          {
            name: 'Safari',
            version: 10,
          },
          {
            name: 'Edge',
            version: 14,
          },
        ]}
        history={history}
        App={App}
        UnsupportedBrowserPage={UnsupportedBrowser}
      />
    </ScrollToTop>
  </ConnectedRouter>
  {/* </LanguageProvider> */}
</Provider>, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
