/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  SIGN_OUT_ERROR,
  SIGN_OUT_SUCCESS,
  SIGN_OUT,
  TOGGLE_NAVBAR,
  UPDATE_SNACK_BAR,
  LOAD_USER,
  LOAD_USER_ERROR,
  LOAD_USER_SUCCESS,
  PRINT_START,
  PRINT_FINISH
} from './constants';

/**
 * toggles app navbar
 *
 * @return {object} An action object with a type of TOGGLE_NAVBAR
 * and navbar expansion state as expanded
 */

export function toggleNavbar(expanded) {
  return {
    type: TOGGLE_NAVBAR,
    expanded
  };
}

/**
 * load authenticated user
 *
 * @return {object} An action object with a type of LOAD_USER
 */
export function loadUser() {
  return {
    type: LOAD_USER,
  };
}

/**
 * Dispatched when the authenticated user is loaded by the request saga
 *
 * @param  {object} data The current authenticated user
 *
 * @return {object} An action object with a type of LOAD_USER_SUCCESS passing the user
 */
export function userLoaded(data) {
  return {
    type: LOAD_USER_SUCCESS,
    data
  };
}

/**
 * Dispatched when loading the user fails
 *
 * @param  {object} error The error
 *
 * @return {object} An action object with a type of LOAD_USER_ERROR passing the error
 */
export function userLoadingError(error) {
  return {
    type: LOAD_USER_ERROR,
    error,
  };
}


/**
 * Dispatched when changing global snackBar state
 *
 * @param  {object} snackBar may contain message and isOpen state
 *
 * @return {object} An action object with a type of TOGGLE_SNACKBAR passing isOpenSnackBar
 */
export function updateSnackBar(snackBar) {
  return {
    type: UPDATE_SNACK_BAR,
    snackBar,
  };
}

/**
 * sign out user
 *
 * @return {object} An action object with a type of SIGN_OUT
 */
export function signOut() {
  return {
    type: SIGN_OUT
  };
}

/**
 * Dispatched when sign out fails
 *
 * @param  {object} error The error
 *
 * @return {object} An action object with a type of SIGN_OUT_ERROR passing the error
 */
export function signOutError(error) {
  return {
    type: SIGN_OUT_ERROR,
    error,
  };
}

/**
 * Dispatched when sign out succeeds
 *
 * @return {object} An action object with a type of SIGN_OUT_SUCCESS
 */
export function signedOut() {
  return {
    type: SIGN_OUT_SUCCESS,
  };
}

/**
 * Dispatched when Printing Starts
 *
 * @return {object} An action object with a type of SIGN_OUT_SUCCESS
 */
export function startPrinting() {
  return {
    type: PRINT_START,
  };
}


/**
 * Dispatched when Printing Starts
 *
 * @return {object} An action object with a type of SIGN_OUT_SUCCESS
 */
export function finishPrinting() {
  return {
    type: PRINT_FINISH,
  };
}