import React from 'react';
import { Paper } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import Background from 'components/Background';
import SynergiaAnalyticsLogo from 'components/SynergiaAnalyticsLogo';
import Footer from 'components/Footer';
import Splash from 'components/Login/Splash';
import LoginForm from 'containers/Login/LoginForm';
import ForgotPasswordForm from 'containers/Login/ForgotPasswordForm';
import ChangePasswordForm from 'containers/Login/ChangePasswordForm';
import './style.scss';

const LoginFrame = () => (
  <div className="login-frame">
    <Background />
    <SynergiaAnalyticsLogo />
    <div className="login-frame-content">
      <Paper className="login-frame-paper" elevation={1}>
        <Switch>
          <Route exact path="/" component={Splash} />
          <Route exact path="/login" component={LoginForm} />
          <Route exact path="/forgotPassword" component={ForgotPasswordForm} />
          <Route
            exact
            path="/resetPassword"
            render={props => (
              <ChangePasswordForm
                {...props}
                title="Reset password"
                description="Fill in the details below to reset your password."
                initialButtonText="Reset password"
                loadingButtonText="Resetting..."
              />
            )}
          />
          <Route
            exact
            path="/activateAccount"
            render={props => (
              <ChangePasswordForm
                {...props}
                title="Activate account"
                description="Welcome! To get started, please create a new password."
                initialButtonText="Activate"
                loadingButtonText="Activating..."
              />
            )}
          />
        </Switch>
      </Paper>
    </div>
    <Footer />
  </div>
);

export default LoginFrame;
