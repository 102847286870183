import { API_URL } from 'containers/App/constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { cleanEmptyValues } from 'utils/objectUtil';
import { LOAD_VIEWS } from './constants';
import { viewsLoaded, viewsLoadingError } from './actions';

export function* getViews({ params }) {
  const requestURL = new URL(`${API_URL}/views`);
  const searchParams = cleanEmptyValues(params.toJS());
  searchParams.sort = 'name,asc';
  requestURL.search = new URLSearchParams(searchParams);

  try {
    const data = yield call(request, requestURL);
    yield put(viewsLoaded(data));
  } catch (err) {
    yield put(viewsLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* viewsData() {
  yield takeLatest(LOAD_VIEWS, getViews);
}
