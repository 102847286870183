import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import FormTextField from 'components/FormTextField';
import LoginAlert from 'components/Login/LoginAlert';
import { requireFields, validateEmail } from 'utils/validation/commonValidation';
import { focusIfDesktop } from 'utils/mobile';
import './style.scss';

const validate = values => ({
  ...requireFields(values, {
    email: 'Please enter an email address',
  }),
  ...validateEmail(values),
});

class ForgotPasswordForm extends Component {
  state = { alertClosed: false };

  componentDidMount() {
    focusIfDesktop(this.emailInputElement);
  }

  componentWillUnmount() {
    this.props.onComponentAboutToUnmount();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ alertClosed: false });
    this.props.handleSubmit();
  };

  render() {
    const { loading, sendEmailError, sendEmailSuccess, email } = this.props;
    const showAlert = !this.state.alertClosed && !!sendEmailError;
    return (
      <form className="login-frame-form forgot-password-form" onSubmit={this.handleSubmit}>
        <div className="login-frame-form-title">Forgot password</div>
        {!sendEmailSuccess ? (
          <Fragment>
            <div className="forgot-password-form-link-text">
              {"Enter your email address below and we'll send you a link to reset your password."}
            </div>
            <Field
              name="email"
              label="Email"
              className="forgot-password-form-email-field"
              component={FormTextField}
              spellCheck="false"
              autoCorrect="false"
              autoCapitalize="false"
              inputRef={e => {
                this.emailInputElement = e;
              }}
            />
            <LoginAlert
              showing={showAlert}
              text={sendEmailError}
              onClose={() => this.setState({ alertClosed: true })}
            />
            <div className="login-frame-form-buttons">
              <Link to="/login">Back to login</Link>
              <Button
                className={classNames('login-frame-button', 'send-reset-button', loading && 'loading')}
                type="submit"
                variant="raised"
                color="primary"
              >
                {loading ? 'Sending...' : 'Send reset email'}
              </Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div>A password reset email has been sent to</div>
            <div className="forgot-password-form-email-text">{email}</div>
            <div className="forgot-password-form-help-text">
              {
                "If you don't receive an email in 5-10 min, please check your spam \
                folder and verify that this email address is associated with an account."
              }
            </div>
            <div className="login-frame-form-buttons">
              <Link to="/login">Back to login</Link>
            </div>
          </Fragment>
        )}
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  onComponentAboutToUnmount: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  sendEmailError: PropTypes.string,
  sendEmailSuccess: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired, // Provided by redux-form.
};

export default reduxForm({ form: 'forgotPasswordForm', touchOnBlur: false, validate })(ForgotPasswordForm);
