import isEmpty from 'lodash/isEmpty';
import { requireFields, validateEmail } from './commonValidation';

export const passwordCriteria = {
  length: {
    message: 'Minimum 10 characters',
    validate: value => value.length >= 10,
  },
};

export const validateNewPassword = values => {
  const errors = {};
  const newPasswordValue = values && values.get('newPassword');
  if (newPasswordValue) {
    const passwordCriteriaErrors = {};
    Object.entries(passwordCriteria).forEach(([key, criterion]) => {
      const valid = criterion.validate(newPasswordValue);
      if (!valid) {
        passwordCriteriaErrors[key] = true;
      }
    });
    if (!isEmpty(passwordCriteriaErrors)) {
      errors.newPassword = {
        passwordCriteriaErrors,
        fieldErrorMessage: 'Please choose a stronger password',
      };
    }
  } else {
    errors.newPassword = { fieldErrorMessage: 'Please enter a password' };
  }
  return errors;
};

export const validateConfirmPassword = values => {
  const errors = {};
  if (values) {
    const newPassword = values.get('newPassword');
    const confirmedPassword = values.get('confirmNewPassword');
    if (newPassword && confirmedPassword && newPassword !== confirmedPassword) {
      errors.confirmNewPassword = 'Passwords do not match';
    }
  }
  return errors;
};

export const validatePasswordForm = values => ({
  ...requireFields(values, {
    email: 'Please enter an email address',
    confirmNewPassword: 'Please confirm your password',
  }),
  ...validateNewPassword(values),
  ...validateConfirmPassword(values),
  ...validateEmail(values),
});
