/*
 * Views Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_VIEWS = 'WNSW/VIEWS/LOAD_VIEWS';
export const LOAD_VIEWS_SUCCESS = 'WNSW/VIEWS/LOAD_VIEWS_SUCCESS';
export const LOAD_VIEWS_ERROR = 'WNSW/VIEWS/LOAD_VIEWS_ERROR';

export const TOGGLE_VIEW_SELECTED = 'WNSW/VIEWS/TOGGLE_VIEW_SELECTED';
export const TOGGLE_ALL_VIEWS = 'WNSW/VIEWS/TOGGLE_ALL_VIEWS';
export const RESET_SELECTED_VIEWS = 'WNSW/VIEWS/RESET_SELECTED_VIEWS';
