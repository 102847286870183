/*
 * Admin reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';
import { UPDATE_FILTER, RESET_FILTERS, SET_FILTERS } from './constants';

const initialState = fromJS({
  loading: false,
  error: false,
  filters: {
    page: 0,
    size: 30,
    'group.name': '',
    'group.group_type': '',
    'user.first_name': '',
    'view.name': '',
    'view.not_id': '',
    'facility.name': '',
    'user.not_username': ''
  }
});

function adminReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILTER:
      return state
        .setIn(['filters', action.field], action.value);
    case SET_FILTERS:
      return state
        .mergeIn(['filters'], action.filters);
    case RESET_FILTERS:
      return state
        .set('filters', initialState.get('filters'));
    default:
      return state;
  }
}

export default adminReducer;
