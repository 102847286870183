import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, DialogContentText, Button, DialogTitle } from '@material-ui/core';
import Dialog from 'containers/Dialog';
import * as constants from 'containers/Dialog/constants';

class ConfirmDialog extends Component {
  handleConfirm = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
    this.props.closeDialog();
  };

  render() {
    const { title, description, confirmButtonText, closeDialog } = this.props;
    return (
      <Dialog
        dialogKey={constants.CONFIRM_DIALOG_KEY}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{title || ''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={this.handleConfirm} color="primary">
            {confirmButtonText || ''}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  closeDialog: PropTypes.func.isRequired,
};

export default ConfirmDialog;
