import React from 'react';
import PropTypes from 'prop-types';

const LumioIcon = ({ icon, ...rest }) => <span className={`lumio-icon lx-${icon}`} {...rest} />;

LumioIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default LumioIcon;
