import React from 'react';
import './style.scss';

const SynergiaAnalyticsLogo = () => (
  <div className="synergia-analytics-logo">
    <div className="synergia-swirl" />
    <div className="synergia-text">synergia</div>
    <div className="analytics-text">analytics</div>
    <div className="powered-by-text">powered by</div>
  </div>
);

export default SynergiaAnalyticsLogo;
