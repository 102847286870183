/*
 * AppReducer - global reducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS, Map } from 'immutable';
import { LOAD_GROUPS, LOAD_GROUPS_ERROR, LOAD_GROUPS_SUCCESS } from 'containers/Admin/Groups/constants';
import {
  LOAD_GROUP_DETAILS,
  LOAD_GROUP_DETAILS_ERROR,
  LOAD_GROUP_DETAILS_SUCCESS,
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  SAVE_GROUP_SUCCESS,
  SAVE_GROUP_ERROR,
} from 'containers/Admin/Groups/GroupDetails/constants';

import { LOAD_FACILITIES_ERROR, LOAD_FACILITIES_SUCCESS, LOAD_FACILITIES } from 'containers/Admin/Facilities/constants';

import {
  LOAD_FACILITY_DETAILS_ERROR,
  LOAD_FACILITY_DETAILS_SUCCESS,
  LOAD_FACILITY_DETAILS,
  SET_FACILITY_GROUPS,
  SET_FACILITY_GROUPS_ERROR,
  SET_FACILITY_GROUPS_SUCCESS,
} from 'containers/Admin/Facilities/FacilityDetails/constants';

import { LOAD_USERS, LOAD_USERS_ERROR, LOAD_USERS_SUCCESS } from 'containers/Admin/Users/constants';
import {
  LOAD_USER_DETAILS,
  LOAD_USER_DETAILS_ERROR,
  LOAD_USER_DETAILS_SUCCESS,
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  SAVE_USER,
  SAVE_USER_ERROR,
  SAVE_USER_SUCCESS,
  SET_USER_GROUPS,
  SET_USER_GROUPS_ERROR,
  SET_USER_GROUPS_SUCCESS,
} from 'containers/Admin/Users/UserDetails/constants';

import {
  LOAD_MEASURE_DEFINITIONS_ERROR,
  LOAD_MEASURE_DEFINITIONS,
  LOAD_MEASURE_DEFINITIONS_SUCCESS,
  LOAD_CONTENT,
  LOAD_CONTENT_ERROR,
  LOAD_CONTENT_SUCCESS,
} from 'containers/Dashboard/Content/constants';

import { LOAD_VIEWS, LOAD_VIEWS_ERROR, LOAD_VIEWS_SUCCESS } from 'containers/Admin/Views/constants';
import {
  LOAD_USER,
  LOAD_USER_ERROR,
  LOAD_USER_SUCCESS,
  TOGGLE_NAVBAR,
  UPDATE_SNACK_BAR,
  SIGN_OUT_ERROR,
  SIGN_OUT_SUCCESS,
  PRINT_START,
  PRINT_FINISH,
} from './constants';

// The initial state of the App
const initialState = fromJS({
  loading: false,
  printing: false,
  error: false,
  data: {
    user: false,
    groups: [],
  },
  navbar: {
    expanded: false,
  },
  snackBar: {
    isOpen: false,
    message: '',
  },
});

function containsAdminGroup(groups) {
  return groups ? groups.some(g => g.group_type === 'admin' || g.group_type === 'super') : false;
}

function appReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_NAVBAR:
      return state.setIn(['navbar', 'expanded'], action.expanded);
    case UPDATE_SNACK_BAR:
      return state.mergeIn(['snackBar'], fromJS(action.snackBar));
    case LOAD_GROUPS:
    case LOAD_VIEWS:
    case LOAD_GROUP_DETAILS:
    case CREATE_GROUP:
    case LOAD_FACILITIES:
    case LOAD_FACILITY_DETAILS:
    case LOAD_USERS:
    case LOAD_USER_DETAILS:
    case CREATE_USER:
    case RESET_PASSWORD:
    case SET_FACILITY_GROUPS:
    case SAVE_USER:
    case SET_USER_GROUPS:
    case LOAD_CONTENT:
    case LOAD_MEASURE_DEFINITIONS:
      return state.set('loading', true);
    case LOAD_GROUP_DETAILS_ERROR:
    case LOAD_GROUP_DETAILS_SUCCESS:
    case LOAD_FACILITY_DETAILS_SUCCESS:
    case LOAD_FACILITY_DETAILS_ERROR:
    case LOAD_FACILITIES_ERROR:
    case LOAD_FACILITIES_SUCCESS:
    case LOAD_GROUPS_ERROR:
    case LOAD_GROUPS_SUCCESS:
    case CREATE_GROUP_SUCCESS:
    case CREATE_GROUP_ERROR:
    case SAVE_GROUP_SUCCESS:
    case SAVE_GROUP_ERROR:
    case LOAD_USERS_ERROR:
    case LOAD_USERS_SUCCESS:
    case LOAD_USER_DETAILS_ERROR:
    case LOAD_USER_DETAILS_SUCCESS:
    case LOAD_VIEWS_ERROR:
    case LOAD_VIEWS_SUCCESS:
    case CREATE_USER_SUCCESS:
    case CREATE_USER_ERROR:
    case RESET_PASSWORD_ERROR:
    case RESET_PASSWORD_SUCCESS:
    case SET_FACILITY_GROUPS_ERROR:
    case SET_FACILITY_GROUPS_SUCCESS:
    case SAVE_USER_ERROR:
    case SAVE_USER_SUCCESS:
    case SET_USER_GROUPS_SUCCESS:
    case SET_USER_GROUPS_ERROR:
    case LOAD_MEASURE_DEFINITIONS_SUCCESS:
    case LOAD_MEASURE_DEFINITIONS_ERROR:
    case LOAD_CONTENT_SUCCESS:
    case LOAD_CONTENT_ERROR:
      return state.set('loading', false);
    case LOAD_USER:
      return state
        .set('loading', true)
        .set('error', false)
        .setIn(['data', 'user'], false);
    case LOAD_USER_SUCCESS:
      return state
        .set('data', fromJS(action.data))
        .setIn(['data', 'user', 'isAdmin'], containsAdminGroup(action.data.groups))
        .set('loading', false);
    case LOAD_USER_ERROR:
      return state
        .setIn(['data', 'user'], Map())
        .set('error', action.error)
        .set('loading', false);
    case SIGN_OUT_ERROR:
    case SIGN_OUT_SUCCESS:
      return state.setIn(['data', 'user'], Map());
    case PRINT_START:
      return state.set('printing', true);
    case PRINT_FINISH:
      return state.set('printing', false);
    default:
      return state;
  }
}

export default appReducer;
