import { createSelector } from 'reselect';
import { List } from 'immutable';
import order from './domainOrder';

const selectViews = state => state.get('views');

const selectViewsData = createSelector(
  selectViews,
  state => state.get('data')
);

const selectViewsList = createSelector(
  selectViewsData,
  state => state.get('list') || List()
);

const selectViewsGroupedByDomain = createSelector(
  selectViewsList,
  views =>
    views
      .sortBy(view => order.indexOf(view.get('view_domain')))
      .sort((a, b) => -a.get('view_type').localeCompare(b.get('view_type')))
      .groupBy(view => view.get('view_domain'))
);

const selectSelectedViews = createSelector(
  selectViewsList,
  views => views.filter(view => view.get('selected'))
);

const selectSelectedViewIds = createSelector(
  selectViews,
  views => views.get('selectedViewIds')
);

const makeSelectViewsTotal = () =>
  createSelector(
    selectViewsData,
    state => state.get('total')
  );

const makeSelectViews = () => selectViewsList;

const makeSelectSelectedViews = () => selectSelectedViews;
const makeSelectSelectedViewIds = () => selectSelectedViewIds;

const makeSelectSelectedViewsTotal = () =>
  createSelector(
    selectSelectedViews,
    views => views.size
  );

const makeSelectSelectedViewIdsTotal = () =>
  createSelector(
    selectSelectedViewIds,
    views => views.size
  );

const makeSelectViewsGroupedByDomain = () => selectViewsGroupedByDomain;

export {
  makeSelectViews,
  makeSelectSelectedViewsTotal,
  makeSelectViewsTotal,
  makeSelectSelectedViews,
  makeSelectSelectedViewIds,
  makeSelectSelectedViewIdsTotal,
  makeSelectViewsGroupedByDomain,
};
