import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './style.scss';

const Splash = ({ history }) => (
  <div className="splash">
    <div className="phn-logo" />
    <div className="splash-title">Western NSW PHN</div>
    <div className="splash-subtitle">Primary Health Information Platform</div>
    <Button variant="raised" color="primary" onClick={() => history.push('/login')}>
      Sign In
    </Button>
  </div>
);

Splash.propTypes = {
  history: PropTypes.object.isRequired, // Provided by react-router.
};

export default withRouter(Splash);
