import { fromJS } from 'immutable';
import { INITIALIZE, SIGN_IN, SIGN_IN_ERROR, SIGN_IN_SUCCESS } from './constants';

const initialState = fromJS({
  loading: false,
  loginError: null,
  loginSuccess: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return initialState;
    case SIGN_IN:
      return state
        .set('loading', true)
        .set('loginError', null)
        .set('loginSuccess', false);
    case SIGN_IN_ERROR:
      return state.set('loginError', action.error).set('loading', false);
    case SIGN_IN_SUCCESS:
      return state.set('loginSuccess', true).set('loading', false);
    default:
      return state;
  }
};

export default reducer;
