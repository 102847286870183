import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import './style.scss';

class UserMenu extends PureComponent {
  state = { anchorEl: null };

  handleMenuButtonClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { isAdmin, signOut } = this.props;
    const { anchorEl } = this.state;
    return (
      <div className="user-menu-root no-print">
        <IconButton
          aria-owns={anchorEl ? 'user-menu' : null}
          aria-haspopup="true"
          color="inherit"
          className="user-menu-button"
          onClick={this.handleMenuButtonClick}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          onClose={this.handleClose}
          anchorEl={anchorEl}
          open={!!anchorEl}
          id="user-menu"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {isAdmin && (
            <Link className="plain-link" to="/admin">
              <MenuItem onClick={this.handleClose}>Admin</MenuItem>
            </Link>
          )}
          <MenuItem onClick={signOut}>Sign out</MenuItem>
        </Menu>
      </div>
    );
  }
}

UserMenu.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default UserMenu;
