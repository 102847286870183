import { Map } from 'immutable';
import * as constants from './constants';

export const openDialog = (key, data) => ({ type: constants.OPEN_DIALOG, key, data });

export const closeDialog = key => ({ type: constants.CLOSE_DIALOG, key });

export const openConfirmDialog = (title, description, confirmButtonText, onConfirm) => {
  const data = Map({ title, description, confirmButtonText, onConfirm });
  return openDialog(constants.CONFIRM_DIALOG_KEY, data);
};
