/*
 * Users Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_USERS = 'WNSW/USERS/LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'WNSW/USERS/LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'WNSW/USERS/LOAD_USERS_ERROR';

export const TOGGLE_USER_SELECTED = 'WNSW/USERS/TOGGLE_USER_SELECTED';
export const TOGGLE_ALL_USERS = 'WNSW/USERS/TOGGLE_ALL_USERS';
export const RESET_SELECTED_USERS = 'WNSW/USERS/RESET_SELECTED_USERS';