/*
 * Groups Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_GROUPS = 'WNSW/GROUPS/LOAD_GROUPS';
export const LOAD_GROUPS_SUCCESS = 'WNSW/GROUPS/LOAD_GROUPS_SUCCESS';
export const LOAD_GROUPS_ERROR = 'WNSW/GROUPS/LOAD_GROUPS_ERROR';
export const TOGGLE_GROUP_SELECTED = 'WNSW/GROUPS/TOGGLE_GROUP_SELECTED';
export const RESET_SELECTED_GROUPS = 'WNSW/GROUPS/RESET_SELECTED_GROUPS';
