// from http://emailregex.com/
const EMAIL_ADDRESS_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const requireFields = (values, fields) => {
  const errors = {};
  Object.entries(fields).forEach(([key, message]) => {
    if (!values || !values.get(key)) {
      errors[key] = message;
    }
  });
  return errors;
};

export const validateEmail = (values, emailField = 'email') => {
  const errors = {};
  if (values && values.get(emailField) && !EMAIL_ADDRESS_REGEX.test(values.get(emailField))) {
    errors[emailField] = 'Please enter a valid email address';
  }
  return errors;
};
