import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import saga from './saga';
import { signIn, initialize } from './actions';
import LoginForm from './LoginForm';

// Note: toJS() creates new objects each time, but we can use it here because there are no object props, only primitives.
const mapStateToProps = state => state.get('loginForm').toJS();

const mapDispatchToProps = dispatch => ({
  resetToInitialState: () => dispatch(initialize()),
  onSubmit: values => {
    const email = values.get('email');
    const password = values.get('password');
    dispatch(signIn(email, password));
  },
});

const withSaga = injectSaga({ key: 'loginForm', saga });
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withSaga,
  withConnect
)(LoginForm);
