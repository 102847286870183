import { createMuiTheme } from '@material-ui/core/styles';
import { isIE } from 'utils/browser';

// See global-styles.scss.
const purple1 = '#161145';
const purple2 = '#2b2573';
const purple4 = '#6500ff';
const blue1 = '#26c6da';
const grey1 = '#434343';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: purple1,
    },
    secondary: {
      main: blue1,
    },
  },
  typography: {
    fontFamily: isIE() ? 'Cabin, sans-serif' : 'AtSymbolOverride, Cabin, sans-serif',
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '40px',
        fontWeight: 'normal',
      },
      containedPrimary: {
        backgroundColor: purple2,
        '&:hover': {
          backgroundColor: '#332b92',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: purple4,
        },
      },
    },
    MuiInput: {
      root: {
        color: grey1,
      },
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: purple4,
          borderBottomWidth: '1px',
        },
        '&:after': {
          borderBottomColor: purple4,
        },
      },
    },
  },
});
