import { call, put, takeLatest, all } from 'redux-saga/effects';
import { signInSuccess } from 'containers/Login/LoginForm/actions';
import request from 'utils/request';
import { API_URL } from '../../App/constants';
import { CHANGE_PASSWORD } from './constants';
import { SIGN_IN } from '../LoginForm/constants';
import { signIn as signInSaga } from '../LoginForm/saga';
import { changePasswordError, changePasswordSuccess } from './actions';
import { loadUser } from '../../App/actions';

function* changePassword(action) {
  const requestURL = `${API_URL}/change-password`;
  try {
    yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify({ email: action.email, new_password: action.newPassword, reset_token: action.resetToken }),
    });
    yield put(changePasswordSuccess(action.email));
    yield put(signInSuccess());
    yield put(loadUser());
  } catch (error) {
    yield put(changePasswordError('Unable to change password at this time.'));
  }
}

export default function* saga() {
  yield all([takeLatest(CHANGE_PASSWORD, changePassword), takeLatest(SIGN_IN, signInSaga)]);
}
