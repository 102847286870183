import { connect } from 'react-redux';
import * as actions from './actions';
import Dialog from './Dialog';

const mapStateToProps = (state, ownProps) => ({
  open: state.getIn(['dialog', 'key']) === ownProps.dialogKey,
  data: state.getIn(['dialog', 'data']),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => dispatch(actions.closeDialog(ownProps.dialogKey)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dialog);
