import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import FormTextField from 'components/FormTextField';
import LoginAlert from 'components/Login/LoginAlert';
import { requireFields, validateEmail } from 'utils/validation/commonValidation';
import { focusIfDesktop } from 'utils/mobile';
import './style.scss';

const validate = values => ({
  ...requireFields(values, {
    email: 'Please enter an email address',
    password: 'Please enter a password',
  }),
  ...validateEmail(values),
});

class LoginForm extends Component {
  state = { alertClosed: false };

  componentDidMount() {
    focusIfDesktop(this.emailInputElement);
  }

  componentWillUnmount() {
    this.props.resetToInitialState();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ alertClosed: false });
    this.props.handleSubmit();
  };

  render() {
    const { loading, loginError, loginSuccess } = this.props;
    const showAlert = !this.state.alertClosed && !!loginError;
    let buttonText;
    if (loginSuccess) {
      buttonText = 'Success!';
    } else if (loading) {
      buttonText = 'Signing in...';
    } else {
      buttonText = 'Sign in';
    }
    return (
      <form className="login-frame-form" onSubmit={this.handleSubmit}>
        <div className="login-frame-form-title">Sign in</div>
        <Field
          className="login-form-email-field"
          name="email"
          label="Email"
          component={FormTextField}
          spellCheck="false"
          autoCorrect="false"
          autoCapitalize="false"
          inputRef={e => {
            this.emailInputElement = e;
          }}
        />
        <Field
          className="login-form-password-field"
          name="password"
          type="password"
          label="Password"
          component={FormTextField}
        />
        <LoginAlert showing={showAlert} text={loginError} onClose={() => this.setState({ alertClosed: true })} />
        <div className="login-frame-form-buttons">
          <Link to="/forgotPassword">Forgot password?</Link>
          <Button
            className={classNames('login-frame-button', 'login-button', (loading || loginSuccess) && 'loading')}
            type="submit"
            variant="raised"
            color="primary"
          >
            {buttonText}
          </Button>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired, // Provided by redux-form.
  loading: PropTypes.bool.isRequired,
  loginSuccess: PropTypes.bool.isRequired,
  loginError: PropTypes.string,
  resetToInitialState: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'loginForm', touchOnBlur: false, validate })(LoginForm);
