/*
 * Content Details Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'PROJECT_NAME/COMPONENT_NAME' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'PROJECT_NAME/CONTAINER_NAME/YOUR_ACTION_CONSTANT';
 */

export const LOAD_VIEW_DETAILS = 'WNSW/VIEW_DETAILS/LOAD_VIEW_DETAILS';
export const LOAD_VIEW_DETAILS_SUCCESS = 'WNSW/VIEW_DETAILS/LOAD_VIEW_DETAILS_SUCCESS';
export const LOAD_VIEW_DETAILS_ERROR = 'WNSW/VIEW_DETAILS/LOAD_VIEW_DETAILS_ERROR';

export const SET_VIEW_GROUPS = 'WNSW/VIEW_DETAILS/SET_VIEW_GROUPS';
export const SET_VIEW_GROUPS_SUCCESS = 'WNSW/VIEW_DETAILS/SET_VIEW_GROUPS_SUCCESS';
export const SET_VIEW_GROUPS_ERROR = 'WNSW/VIEW_DETAILS/SET_VIEW_GROUPS_ERROR';

export const RESET_VIEW = 'WNSW/VIEW_DETAILS/RESET_VIEW';

export const REMOVE_VIEW_FROM_GROUP = 'WNSW/VIEW_DETAILS/REMOVE_VIEW_FROM_GROUP';
export const VIEW_REMOVED_FROM_GROUP = 'WNSW/VIEW_DETAILS/VIEW_REMOVED_FROM_GROUP';

export const REMOVE_VIEW_FROM_COHORT = 'WNSW/VIEW_DETAILS/REMOVE_VIEW_FROM_COHORT';
export const VIEW_REMOVED_FROM_COHORT = 'WNSW/VIEW_DETAILS/VIEW_REMOVED_FROM_COHORT';

export const SORT_VIEW_COHORTS = 'WNSW/VIEW_DETAILS/SORT_VIEW_COHORTS';
export const SET_VIEW_COHORTS_SORT_SUCCESS = 'WNSW/VIEW_DETAILS/SET_VIEW_COHORTS_SORT_SUCCESS';
