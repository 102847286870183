const browser = (function browserFunc() {
  const ua = navigator.userAgent;
  let tem;
  let M;
  M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }
  return { name: M[0], version: M[1] };
})();

export function isSupported(minBrowsers) {
  let support = true;
  minBrowsers.forEach(minBrowser => {
    if (minBrowser.name === browser.name && browser.version < minBrowser.version) {
      support = false;
    }
  });
  return support;
}

export const isIE = () => browser.name && browser.name.startsWith('IE');

export default browser;
