import { fromJS } from 'immutable';
import * as constants from './constants';

const initialState = fromJS({
  loading: false,
  changePasswordError: null,
  changePasswordSuccess: false,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CHANGE_PASSWORD:
      return state
        .set('loading', true)
        .set('changePasswordError', null)
        .set('changePasswordSuccess', false);
    case constants.CHANGE_PASSWORD_ERROR:
      return state.set('loading', false).set('changePasswordError', action.error);
    case constants.CHANGE_PASSWORD_SUCCESS:
      return state.set('loading', false).set('changePasswordSuccess', true);
    default:
      return state;
  }
};

export default reducer;
