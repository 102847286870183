import React from 'react';
import PropTypes from 'prop-types';
import { isSupported } from 'utils/browser';
import { Redirect, Route } from 'react-router-dom';

const BrowserSupport = ({
  redirectUrl, minBrowsers, App, UnsupportedBrowserPage, history
}) => {
  if (!isSupported(minBrowsers)) {
    return history.location.pathname !== redirectUrl ?
      <Redirect to={{ pathname: redirectUrl }} />
      : <UnsupportedBrowserPage />;
  }
  if (history.location.pathname === redirectUrl) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return <Route component={App} />;
};


BrowserSupport.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  minBrowsers: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  UnsupportedBrowserPage: PropTypes.func.isRequired,
  App: PropTypes.func.isRequired
};

export default BrowserSupport;
