import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form/immutable';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import FormTextField from 'components/FormTextField';
import NewPasswordFormField from 'components/Login/NewPasswordFormField';
import LoginAlert from 'components/Login/LoginAlert';
import { validatePasswordForm as validate } from 'utils/validation/passwordValidation';
import { focusIfDesktop } from 'utils/mobile';
import './style.scss';

class ChangePasswordForm extends Component {
  state = { alertClosed: false };

  componentDidMount() {
    focusIfDesktop(this.emailInputElement);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ alertClosed: false });
    this.props.handleSubmit();
  };

  render() {
    const {
      loading,
      changePasswordError,
      changePasswordSuccess,
      title,
      description,
      initialButtonText,
      loadingButtonText,
    } = this.props;

    const showAlert = !this.state.alertClosed && !!changePasswordError;
    let buttonText;
    if (changePasswordSuccess) {
      buttonText = 'Success!';
    } else if (loading) {
      buttonText = loadingButtonText;
    } else {
      buttonText = initialButtonText;
    }

    return (
      <form className="login-frame-form change-password-form" onSubmit={this.handleSubmit}>
        <div className="login-frame-form-title">{title}</div>
        <div>{description}</div>
        <Field
          name="email"
          label="Email"
          className="change-password-email-field"
          component={FormTextField}
          spellCheck="false"
          autoCorrect="false"
          autoCapitalize="false"
          inputRef={e => {
            this.emailInputElement = e;
          }}
        />
        <Field name="newPassword" type="password" label="New password" component={NewPasswordFormField} />
        <Field
          className="confirm-new-password-field"
          name="confirmNewPassword"
          type="password"
          label="Confirm new password"
          component={FormTextField}
        />
        <LoginAlert
          showing={showAlert}
          text={changePasswordError}
          onClose={() => this.setState({ alertClosed: true })}
        />
        <div className="login-frame-form-buttons">
          <Link to="/login">Back to login</Link>
          <Button
            className={classNames(
              'login-frame-button',
              'change-password-button',
              (loading || changePasswordSuccess) && 'loading'
            )}
            type="submit"
            variant="raised"
            color="primary"
          >
            {buttonText}
          </Button>
        </div>
      </form>
    );
  }
}

ChangePasswordForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  changePasswordError: PropTypes.string,
  changePasswordSuccess: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  initialButtonText: PropTypes.string.isRequired,
  loadingButtonText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired, // Provided by redux-form.
};

export default reduxForm({ form: 'changePasswordForm', touchOnBlur: false, validate })(ChangePasswordForm);
